import React, { type ComponentProps, useCallback } from 'react'
import { get, type FieldValues, set, type DefaultValues } from 'react-hook-form'
import { ErrorBoundary } from 'react-error-boundary'
import { Button } from '@mui/material'

import useParseSchema from '../hooks/useParseSchema'
import type { FormSchema } from '../types/schema'
import type { ComponentMapBase, FormCustomContext } from '../types/schema-base'
import SchemaFormComponents from './schema/SchemaFormComponents'
import Form from './Form'
import { FormProps } from '../types/FormProps'
import { FormSchemaProvider } from './data/FormSchemaProvider'
import CalculatedField from '../features/calculatedFields/CalculatedField'
import DebugErrorBoundaryFallback from './DebugErrorBoundaryFallback'
import { AdvancedSubmitHandler } from '../types/types'

export type SchemaFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TContext extends FormCustomContext = FormCustomContext,
  TSchema extends FormSchema<TFieldValues, TComponentMap, TContext> = FormSchema<TFieldValues, TComponentMap, TContext>,
> = Omit<FormProps<TFieldValues, TComponentMap, TContext>, 'defaultValues' | 'validationSchema' | 'visibilitySchema'> &
  Partial<Omit<ComponentProps<TForm>, 'onSubmit' | 'defaultValues' | 'validationSchema' | 'visibilitySchema'>> & {
    onSubmit: AdvancedSubmitHandler<TFieldValues>
    schema: TSchema
    layout?: React.ComponentType
    FormComponent?: TForm
    // redefine defaultValues here to narrow the type
    defaultValues?: DefaultValues<TFieldValues>
    hideSubmitButton?: boolean
    ButtonComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  }

function _SchemaForm<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TForm extends React.ComponentType<any> = React.ComponentType,
  TContext extends FormCustomContext = FormCustomContext,
>({
  schema,
  defaultValues,
  layout: Layout,
  FormComponent,
  onSubmit,
  hideSubmitButton,
  ButtonComponent,
  ...props
}: SchemaFormProps<TFieldValues, TComponentMap, TForm, TContext, FormSchema<TFieldValues, TComponentMap, TContext>>) {
  const {
    formProps,
    blocks,
    blockIndex,
    error: parseError,
  } = useParseSchema<TFieldValues, TComponentMap, TContext>(schema, defaultValues)

  const Cmp = FormComponent ?? Form

  const handleSubmit = useCallback<AdvancedSubmitHandler<TFieldValues>>(
    async (values, event, ephemerals) => {
      if (parseError) return

      const _values = { ...values } as Record<keyof TFieldValues, unknown>

      if (schema.transforms) {
        for (const key in blockIndex) {
          // check value can be submitted
          if (ephemerals?.includes(key)) {
            continue
          }

          const fieldType = blockIndex[key].type

          if (fieldType in schema.transforms && schema.transforms[fieldType]) {
            set(_values, key, schema.transforms[fieldType]?.(get(_values, key), 'out'))
          }
        }
      }

      return onSubmit(_values as TFieldValues, event, formProps.ephemerals)
    },
    [parseError, schema.transforms, onSubmit, formProps?.ephemerals, blockIndex],
  )

  const showErrorBoundary = !!(import.meta.env.DEV === true || props.debug)

  if (showErrorBoundary && parseError) {
    return <DebugErrorBoundaryFallback error={parseError} />
  }

  const ButtonCmp = ButtonComponent ? (
    <ButtonComponent>Submit</ButtonComponent>
  ) : (
    <Button size="large" type="submit">
      Submit
    </Button>
  )

  const content = !parseError && (
    <FormSchemaProvider<TContext, TFieldValues, TComponentMap> value={{ schema, blockIndex }}>
      <Cmp {...props} {...formProps} onSubmit={handleSubmit}>
        {Layout ? <Layout /> : <SchemaFormComponents<TFieldValues, TContext> components={blocks} />}

        {schema.calculatedFields &&
          Object.entries(schema.calculatedFields).map(([fieldName, config]) => (
            <CalculatedField {...config} name={fieldName} key={fieldName} />
          ))}

        {!FormComponent && !hideSubmitButton && ButtonCmp}
      </Cmp>
    </FormSchemaProvider>
  )

  if (showErrorBoundary) {
    return <ErrorBoundary FallbackComponent={DebugErrorBoundaryFallback}>{content}</ErrorBoundary>
  }

  return content
}

const SchemaForm = React.memo(_SchemaForm) as typeof _SchemaForm

export default SchemaForm
