import { DataAdapters, DataProviderSchema, type DataAdapterTypes, FieldDataSchema } from './dataProvider.types'
import Field, { FieldProps } from '../../components/Field'
import { ComponentProps, memo } from 'react'
import { FieldTypes } from '../../types/types'
import { FieldValues } from 'react-hook-form'
import { FormCustomContext } from '../../types/schema-base'

export interface FieldWithDataProps<
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = FieldValues,
  TAdapter extends keyof DataAdapterTypes = keyof DataAdapterTypes,
  TAdapterOptions extends DataAdapters[TAdapter] = DataAdapters[TAdapter],
  TDataSchema extends FieldDataSchema<TFieldValues, TAdapter, TContext> = FieldDataSchema<
    TFieldValues,
    TAdapter,
    TContext
  >,
> {
  dataMap: Map<DataProviderSchema<TAdapter, TAdapterOptions>, TDataSchema>
  fieldProps: FieldProps<keyof FieldTypes, TFieldValues, never>
}

const _FieldWithData = <
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = FieldValues,
>({
  fieldProps,
  dataMap,
}: FieldWithDataProps<TContext, TFieldValues>) => {
  let adapterProps = {}

  dataMap.forEach((dataSchema, adapter) => {
    const loadData = adapter.adapterHook({ adapter, dataSchema })
    adapterProps = { ...adapterProps, ...dataSchema.getComponentProps({ adapter, result: loadData }) }
  })

  return <Field {...(fieldProps as ComponentProps<typeof Field>)} {...adapterProps} />
}

const FieldWithData = memo(_FieldWithData) as typeof _FieldWithData

export default FieldWithData
