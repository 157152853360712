import type { AnyObjectSchema, AnySchema, ISchema } from "yup"
import safeReach from "../util/safeReach"
import type { ArrayPath, FieldValues, Path } from "react-hook-form"
import useVisibilitySchema from "./useVisibilitySchema"
import useValidationSchema from "./useValidationSchema"
import { useMemo } from "react"

// search recursively within schema to find schema by name, working backwards from inner schema level
const findSchemaByNameInSchema = (
  schemaName: string,
  outerSchema: AnyObjectSchema | ISchema<AnySchema>,
  currentPath: string
): string | undefined => {
  if (!('fields' in outerSchema)) {
    return undefined
  }

  // Split the current path to get the current level
  const pathParts = currentPath.split('.')

  // Start searching from the current level
  let searchPath = currentPath
  while (pathParts.length > 0) {
    const currentSchema = safeReach(outerSchema, searchPath) as AnyObjectSchema

    if (currentSchema?.fields) {
      for (const fieldName in currentSchema.fields) {
        if (fieldName === schemaName) {
          return searchPath ? `${searchPath}.${fieldName}` : fieldName
        }
      }
    }

    // Move one level up
    pathParts.pop()
    searchPath = pathParts.join('.')
  }

  // Finally check at root level
  return outerSchema.fields[schemaName] ? schemaName : undefined
}

export interface UseFindSchemaDependencyNamesProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues> | ArrayPath<TFieldValues>
}

const useFindSchemaDependencyNames = <TFieldValues extends FieldValues>({ name }: UseFindSchemaDependencyNamesProps<TFieldValues>) => {
  const visibilitySchema = useVisibilitySchema()
  const validationSchema = useValidationSchema()

  return useMemo(() => {
    const innerSchema = visibilitySchema && (safeReach(visibilitySchema, name) as AnySchema)

    const watchNames: string[] = []

    if (!innerSchema) {
      console.error(`No visibility schema defined for ${name}`)
      return []
    }

    const deps = (innerSchema?.deps as Path<TFieldValues>[])

    for (const path of deps) {
      if (!safeReach(validationSchema, path)) { // watchName not at root level
        const watchName = findSchemaByNameInSchema(path, validationSchema, name as string)

        if (!watchName) {
          console.error(`No visibility schema defined for dependency ${path}`)
        } else {
          watchNames.push(watchName)
        }
      } else {
        watchNames.push(path)
      }
    }

    return watchNames?.length === 1 ? watchNames[0] : watchNames
  }, [name, validationSchema, visibilitySchema])
}

export default useFindSchemaDependencyNames