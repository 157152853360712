import { type Organisation, Status } from '@acre/graphql'

const organisation: Organisation = {
  id: 'TEST_ID',
  organisation_id: 'TEST_ID',
  external_id: null,
  name: null,
  website: null,
  status: Status.Active,
  organisation_legal_form: null,
  fca_reg_no: null,
  joined_acre: null,
  first_time_access: null,
  client_portal_url: null,
  representative_of_org: null,
  representative_of: null,
  arOrganisationsMi: null,
  administered_by: null,
  paid_by: null,
  primary_address: undefined,
  primary_phone: null,
  complaints_address: undefined,
  complaints_phone: null,
  complaints_email: null,
  compliance_email: null,
  complaints_url: null,
  bank_account: null,
  users: [],
  clients: [],
  templates: [],
  fees: [],
  metadata: [],
  standard_fee_options: [],
  general_email: null,
  panel_management: null,
  verification_class: null,
  note_templates: null,
  periods_settings: null,
  logo: null,
  client_portal_settings: null,
  organisation_ext_identifier: null,
  type: null,
  commission_structure: null,
  process_settings: null,
}

export default organisation
