import { type Path } from "react-hook-form";

const mapDeepObject = <T extends Record<string, unknown>, S extends Record<string, unknown>>(obj: S, fn: <K extends keyof S>(key: string, value: S[K], path: Path<S>) => unknown, currentPath = ''): Record<string, unknown> => {
  let path: Path<S>

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key] as S[keyof S];

    path = (currentPath ? `${currentPath}.${key}` : key) as Path<S>

    if (Array.isArray(value)) {
      acc[key] = value.map((v: S[keyof S], index) => {
        if (typeof v === 'object' && v !== null) {
          return mapDeepObject(v as S, fn, `${path}.${index}`);
        }

        return fn(key, v, path);
      });
    } else if (typeof value === 'object' && value !== null) {
      acc[key] = mapDeepObject(value as S, fn, path);
    } else {
      acc[key] = fn(key, value, path);
    }

    return acc;
  }, {} as Record<string, unknown>) as T;
};

export default mapDeepObject