import { format, formatDistanceToNowStrict, parse, parseISO } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'

import { Maybe } from '@acre/graphql'

import { emptyVal } from '../constants'

// In datefns, if you do format(new Date(string)), it will not take into account daylight savings
// Therefore we use this function to get the correct format string
const getFormatStringForShortDate = (date: string) => {
  if (/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(date)) {
    return 'dd-MM-yyyy'
  }
  if (/^[0-9]{4}-[0-9]{2}$/.test(date)) {
    return 'yyyy-MM'
  }
  if (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(date)) {
    return 'dd/MM/yyyy'
  }
  return 'yyyy-MM-dd'
}

const getDate = (date: string) => {
  if (date.length <= 10) {
    return parse(date, getFormatStringForShortDate(date), new Date(), {
      locale: enGB,
    })
  }
  return parseISO(date)
}

export const formatDate = (date: string) => {
  if (date === '') {
    return ''
  }
  if (date.includes('*')) {
    return date.split('-').reverse().join('/')
  }
  try {
    return format(getDate(date), 'dd/MM/yyyy', {
      locale: enGB,
    })
  } catch (e) {
    return ''
  }
}

export const formatDateTime = (date: string) => {
  if (date === '') {
    return ''
  }
  if (date.includes('*')) {
    return formatDate(date)
  }
  try {
    return format(getDate(date), 'dd/MM/yyyy h:mm aaa', {
      locale: enGB,
    })
  } catch (e) {
    return ''
  }
}

export const formatDateWithDash = (date: string) => {
  if (date.includes('*')) {
    return date.split('-').reverse().join('-')
  }

  return format(getDate(date), 'dd-MM-yyyy', {
    locale: enGB,
  })
}

export const momentToApiDate = (date: Date): string => (date ? format(new Date(date), 'yyyy-MM-dd') : date)

export const documentUploadDate = (date_stored?: Maybe<string>) => {
  return date_stored
    ? format(new Date(date_stored), 'yyyy/MM/dd HH:mm', {
        locale: enGB,
      })
    : emptyVal
}

export const docStoredTime = (date_stored?: Maybe<string>) => {
  return date_stored
    ? formatDistanceToNowStrict(new Date(date_stored), {
        locale: enGB,
      })
    : emptyVal
}

export const fullMonthAndYear = (date: string) => {
  return format(new Date(date), 'MMMM yyyy', {
    locale: enGB,
  })
}
