import type { FieldError } from 'react-hook-form'

import type { FieldProps, SimpleFieldProps } from '@acre/design-system'

type GetCommonTranslatedPropsOptions = {
  parseText?: (text: string) => string
  error?: FieldError
}

const getCommonTranslatedProps = <Props extends FieldProps | SimpleFieldProps>(props: Props, opts?: GetCommonTranslatedPropsOptions) => {
  const { parseText, error } = opts || {}

  const label = parseText && typeof props.label === 'string' ? parseText(props.label) : props.label
  const helperText = parseText && typeof props.helperText === 'string' ? parseText(props.helperText) : props.helperText

  let placeholder = undefined

  if ('placeholder' in props && typeof props.placeholder === 'string') {
    placeholder = parseText ? parseText(props.placeholder) : props.placeholder
  }

  const passedError = error?.message || props.error
  const errorMessage = parseText && typeof passedError === 'string' ? parseText(passedError) : passedError

  return { label, helperText, placeholder, error: errorMessage }
}

export default getCommonTranslatedProps
