import React, { createContext, useRef, useState } from 'react'
import { useMemo } from 'react'
import { useContext } from 'react'
import Box from '@mui/material/Box'
import { format, subDays } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'

import { sanitizeHtml } from '@acre/utils'
import { DocumentType, Maybe, Notification, NotificationEventType } from '@acre/graphql'
import {
  Button,
  FeatherIcon,
  GreyText,
  H5,
  IconName,
  PairedColour,
  Size,
  useClickOutsideListener,
} from '@acre/design-system'

import {
  NotificationDescription,
  NotificationGroupTitle,
  NotificationItemStyles,
  NotificationsClearButton,
  NotificationsDot,
  NotificationsEmptyStateHeading,
  NotificationsEmptyStateIconBackground,
  NotificationsToggleButton,
  NotificationsTray,
  NotificationsTrayBody,
  NotificationsTrayHeader,
} from './Notifications.styles'

const NotificationMethodContext = createContext<{ onClearById?: (ids: string[]) => void }>({})

// @ts-ignore (as not all documentTypes are provided)
const documentNotificationMetaMap: {
  [key in DocumentType]: { iconName: IconName; iconColour: PairedColour }
} = {
  [DocumentType.CreditReport]: {
    iconColour: PairedColour.Mint,
    iconName: IconName.CreditCard,
  },
}

export const getNotificationeMeta = ({
  notificationType,
  docType,
}: {
  docType?: Maybe<DocumentType>
  notificationType: NotificationEventType
}): { iconName: IconName; iconColour: PairedColour } => {
  if (notificationType === NotificationEventType.CaseAllocation) {
    return {
      iconName: IconName.Folder,
      iconColour: PairedColour.Turquoise,
    }
  }

  if (notificationType === NotificationEventType.NewSecureMessage) {
    return {
      iconName: IconName.Mail,
      iconColour: PairedColour.Mint,
    }
  }

  const res = docType && documentNotificationMetaMap[docType]
  if (res) {
    return res
  }
  return { iconColour: PairedColour.Navy, iconName: IconName.FileText }
}

const dateFormat = 'dd/MM/yy'

const groupNotificationsByDate = (notifications: Notification[]) =>
  notifications.reduce(
    (acc, item) => {
      const formattedDate = format(new Date(item.created_at), dateFormat)

      return { ...acc, [formattedDate]: [...(acc[formattedDate] || []), item] }
    },
    {} as { [key: string]: Notification[] },
  )

const NotificationGroup = ({ date, items }: { date: string; items: Notification[] }) => {
  const intl = useIntl()
  const today = format(new Date(), dateFormat)
  const yesterday = format(subDays(new Date(), 1), dateFormat)

  let title = date

  if (date === today) title = intl.formatMessage({ id: 'notifications.today' })
  if (date === yesterday) title = intl.formatMessage({ id: 'notifications.yesterday' })

  return (
    <>
      <NotificationGroupTitle>{title}</NotificationGroupTitle>
      {items.map((notification) => (
        <NotificationItem key={notification.notification_id} notification={notification} />
      ))}
    </>
  )
}

const NotificationsEmptyState = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%" p={6} justifyContent="center" alignItems="center">
      <NotificationsEmptyStateIconBackground>
        <FeatherIcon name={IconName.ThumbsUp} size={Size.Medium} />
      </NotificationsEmptyStateIconBackground>
      <NotificationsEmptyStateHeading>
        <FormattedMessage id="notifications.emptyHeading" />
      </NotificationsEmptyStateHeading>
      <p>
        <FormattedMessage id="notifications.emptyText" />
      </p>
    </Box>
  )
}

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const { url, message, subtext, notification_type, notification_id, document_type } = notification

  const { onClearById } = useContext(NotificationMethodContext)

  const { iconName, iconColour } = getNotificationeMeta({
    notificationType: notification_type,
    docType: document_type,
  })

  return (
    <NotificationItemStyles onClick={() => onClearById && onClearById([notification_id])} to={url}>
      <Box>
        <FeatherIcon name={iconName} colour={iconColour} badge size={Size.Medium} />
      </Box>
      <NotificationDescription>
        <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />
        <GreyText>{subtext}</GreyText>
      </NotificationDescription>
    </NotificationItemStyles>
  )
}

export const NotificationButton = ({
  notifications = [],
  onClearAll,
  onClearById,
}: {
  notifications: Notification[]
  onClearAll?: () => void
  onClearById: (ids: string[]) => void
}) => {
  const [isTrayOpen, setTrayOpen] = useState(false)
  const notificationsTrayRef = useRef()

  // Close when the user clicks out of the tray
  useClickOutsideListener(notificationsTrayRef, () => setTrayOpen(false), isTrayOpen)

  const hasUnreadNotifications = notifications && notifications.length > 0

  const groupedNotifications = useMemo(() => {
    return groupNotificationsByDate(notifications)
  }, [notifications])

  const onNotificationClick = (ids: string[]) => {
    onClearById(ids)
    setTrayOpen(false)
  }

  return (
    <NotificationMethodContext.Provider value={{ onClearById: onNotificationClick }}>
      <Box display="flex" sx={(theme) => ({ position: 'relative', height: theme.spacing(6) })}>
        <NotificationsToggleButton active={isTrayOpen} onClick={() => setTrayOpen(!isTrayOpen)}>
          <FeatherIcon name={IconName.Bell} size={Size.Medium} />
          {hasUnreadNotifications && <NotificationsDot />}
        </NotificationsToggleButton>
        {isTrayOpen && (
          <NotificationsTray ref={notificationsTrayRef}>
            <NotificationsTrayHeader>
              <Box display="flex" alignItems="center">
                <H5>
                  <FormattedMessage id="notifications.title" />
                </H5>
                {hasUnreadNotifications && (
                  <Box ml={2}>
                    <NotificationsClearButton onClick={onClearAll} buttonStyle="clear">
                      <FormattedMessage id="notifications.clearAll" />
                    </NotificationsClearButton>
                  </Box>
                )}
              </Box>
              <Button buttonStyle="clear" onClick={() => setTrayOpen(false)}>
                <FeatherIcon name={IconName.X} />
              </Button>
            </NotificationsTrayHeader>
            <NotificationsTrayBody>
              {!hasUnreadNotifications && <NotificationsEmptyState />}
              {hasUnreadNotifications &&
                Object.keys(groupedNotifications).map((date) => (
                  <NotificationGroup key={date} date={date} items={groupedNotifications[date]} />
                ))}
            </NotificationsTrayBody>
          </NotificationsTray>
        )}
      </Box>
    </NotificationMethodContext.Provider>
  )
}
