import React, { ChangeEvent, FocusEvent, ReactElement, ReactNode, useEffect, useState } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useTheme } from '@mui/material'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Location } from 'history'
import { useLocation } from 'react-router-dom'

import { generateUniqueId, useFormatMessage } from '@acre/utils'
import { Maybe } from '@acre/graphql'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { Colour, Height, Size } from '../../utils/types'
import Icon, { IconName } from '../FeatherIcon'
import HelperText from '../HelperText'
import Label from '../Label'
import { trackEventFiltered } from '../UserTracker'
import { FormControlWrapper, LabelAndInputWrapper } from '../../styles/form-control.styles'
import { StyledInput, StyledText } from './TextInput.styles'

export type TextInputProps = {
  id: string
  label?: string | ReactElement
  type?: string
  value?: string | number | null
  error?: boolean
  message?: ReactNode
  name?: string
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  clearOnFocus?: boolean
  isLoading?: boolean
  variant?: Variant
  height?: Height
  fontSize?: number
  hasValue?: boolean
  iconName?: IconName
  labelIcon?: ReactElement
  inlineEdit?: boolean
  labelWidth?: Maybe<string>
  lineHeight?: Maybe<number>
}

const TextInput = ({
  id,
  label,
  type = 'text',
  value,
  error,
  message,
  name,
  disabled: disabledProp,
  ariaLabel,
  placeholder,
  onBlur,
  isLoading,
  onChange,
  onFocus,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  clearOnFocus = false,
  variant = 'default',
  height,
  fontSize,
  hasValue = false,
  iconName,
  labelIcon,
  inlineEdit = false,
  labelWidth,
  lineHeight = 1.4,
}: TextInputProps) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  const disabled = useFieldDisabledState(disabledProp)

  const [nameAttrId, setNameAttrId] = useState('')
  const [showAsInlineEdit, setShowAsInlineEdit] = useState(inlineEdit)

  useEffect(() => {
    const id = generateUniqueId()
    setNameAttrId(id)
  }, [])

  const wrapperClassName = classNames({ error })
  const testId = id ? testHandle(id) : null

  const { trackEvent } = useMatomo()
  // Yes, this is insane but there's no good way to mock `useLocation` with react-router v5
  let location: Location
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation() as Location
  } catch {
    // no-op
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    trackEventFiltered(trackEvent, location, 'textInputChange', id)
    e.target.value = e.target.value.replace('[^0-9.]', '')
    if (inlineEdit) {
      setShowAsInlineEdit(inlineEdit)
    }
    onBlur && onBlur(e)
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    // For input fields that contain sensitive data, like encrypted passwords
    // we want to clear the input on focus rather than letting users edit it
    if (clearOnFocus) {
      e.target.value = ''
      e.currentTarget.value = ''
      onChange && onChange(e)
    }

    onFocus && onFocus(e)
  }

  const handleInputName = () => {
    if (!name) return ''
    // concatinating name with unique ID to prevent autocomplete on text inputs
    return `${name}_${nameAttrId}`
  }

  const inputValue = value === null ? undefined : value

  return (
    <FormControlWrapper
      data-testid={`${testId}Wrapper`}
      className={wrapperClassName}
      onClick={(e) => {
        e.stopPropagation()
        if (showAsInlineEdit) {
          setShowAsInlineEdit(false)
        }
      }}
    >
      <LabelAndInputWrapper variant={variant} hasValue={hasValue} iconName={iconName} labelWidth={labelWidth}>
        {label && (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
            variant={variant}
            lineHeight={lineHeight}
          />
        )}
        <Box display="flex" width="100%" alignItems="center">
          {showAsInlineEdit ? (
            <StyledText>{value}</StyledText>
          ) : (
            <StyledInput
              id={id}
              type={type}
              value={isLoading ? formatMessage('generic.loading') : inputValue}
              name={handleInputName()}
              disabled={disabled}
              autoComplete="chrome-off"
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={handleFocus}
              aria-label={ariaLabel}
              placeholder={placeholder}
              data-testid={testId}
              variant={variant}
              height={height}
              fontSize={fontSize}
            />
          )}
          {labelIcon ? <Box ml={theme.spacers.size16}>{labelIcon}</Box> : null}
        </Box>
        {iconName && (
          <div className="icon">
            <Icon size={Size.Small} name={iconName} colour={hasValue ? Colour.Navy : undefined} />
          </div>
        )}
      </LabelAndInputWrapper>
      {message && (
        <HelperText
          id={`${id}Helper`}
          message={message || ''}
          textType={error ? HELPER_TYPE_ERROR : ''}
          variant={variant}
          labelWidth={labelWidth}
        />
      )}
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </FormControlWrapper>
  )
}

export default withDisabled(TextInput)
