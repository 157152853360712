import { styled, Theme } from '@mui/material'

import { BannerTypes } from './Banner.types'

export interface StyledBannerProps {
  fontSize: number
  type: BannerTypes
  iconPassedAsProp: boolean
  reducedPadding: boolean
  alignCenter: boolean
  spaceBetween: boolean
  margin: string
  centerAlign: boolean
  fullWidth: boolean
}

export const StyledBanner = styled('div')<StyledBannerProps>(
  ({
    fontSize,
    theme,
    type,
    iconPassedAsProp,
    reducedPadding,
    alignCenter,
    spaceBetween,
    margin,
    centerAlign,
    fullWidth,
  }) => `
  /* Shared styles */
  display: flex;
  flex-direction: row;
  padding: ${theme.spacing(reducedPadding ? 1 : 2)};
  border-radius: ${theme.display.borderRadius};
  line-height: 1.6;
  font-size: ${fontSize}px;
  justify-content: ${alignCenter ? 'center' : spaceBetween ? 'space-between' : 'flex-start'};
  margin: ${margin ? `${margin}rem` : '0'};
  align-items: ${centerAlign && 'center'} ;

  p, h5 {
    padding-left: ${theme.spacers.size8};
    max-width: ${fullWidth ? '100%' : '90%'};
  }

   @media (max-width: ${theme.clientWrapper.large}) {
    flex-direction: column;
    button {
      margin-top: 1rem
    }

  },

  /* This is a hack for aligning the icons, because the ones taken from figma are different sizes */
  svg {
    margin-top: ${type === BannerTypes.Warning || type === BannerTypes.Info || type === BannerTypes.Alert || iconPassedAsProp
      ? '0'
      : theme.spacing(0.5)
    };
  }

  

  /* Type-specific styles */
  ${(type === BannerTypes.Success && successStyles(theme)) ||
    (type === BannerTypes.Warning && warningStyles(theme)) ||
    (type === BannerTypes.Info && infoStyles(theme)) ||
    (type === BannerTypes.Alert && alertStyles(theme))
    }
`,
)

export const warningStyles = (theme: Theme) => `
  background-color: ${theme.colours.lightYellow};
  p, h5 {
    color: ${theme.colours.darkYellow};
  }
`

export const successStyles = (theme: Theme) => `
  background-color: ${theme.colours.lightGreen};

  p {
    color: ${theme.colours.darkGreen};
  }
`

export const infoStyles = (theme: Theme) => `
  background-color: ${theme.colours.pattensBlue};

  p {
    color: ${theme.colours.cobalt};
  }

  svg path {
    fill: ${theme.colours.cobalt};
  }
`

export const alertStyles = (theme: Theme) => `
  background-color: ${theme.colours.baseExtraLight};
  p {
    color: ${theme.colours.new.grey6};
  }
`
