import React from 'react'
// import { FormHelperText } from '@mui/material'

import { FieldGroup, FieldGroupProps } from '@acre/design-system'
import { FieldBaseProps, useFormApi } from '@acre/forms'
import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'

// import ErrorMessageDisplay, { RenderErrorMessageProps } from '../../common/ErrorMessageDisplay'
// import { ErrorMessage } from '@acre/forms'

export interface FieldGroupRHFProps
  extends Omit<FieldGroupProps, 'value' | 'onChange' | 'onBlur' | 'inputRef' | 'name'>,
    Omit<FieldBaseProps, 'name'> {
  name: string
}

function _FieldGroupRHF(props: FieldGroupRHFProps) {
  const { parseText } = useFormApi()

  const translatedProps = getCommonTranslatedProps(props, { parseText })

  // const renderError = useCallback(
  //   ({ message, messages }: RenderErrorMessageProps) =>
  //     messages ? (
  //       <FormHelperText id={errorId} component="ul" sx={{ ml: '14px' }} error>
  //         <ErrorMessageDisplay message={message} messages={messages} />
  //       </FormHelperText>
  //     ) : null,
  //   [errorId],
  // )

  return <FieldGroup {...props} {...translatedProps} />
}

const FieldGroupRHF = React.memo(_FieldGroupRHF) as typeof _FieldGroupRHF

export default FieldGroupRHF
