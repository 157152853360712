import { useCallback, useEffect, useRef, useState } from 'react'

const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const cachedKey = useRef(key)

  const localValue = localStorage.getItem(key)

  const [value, setValue] = useState<T>(localValue ? JSON.parse(localValue) : defaultValue)

  /**
   * Update the entire stored value
   */
  const updateLocalStorage = useCallback(
    (newValue: T) => {
      localStorage.setItem(key, JSON.stringify(newValue))

      setValue(newValue)
    },
    [key],
  )

  /**
   * Update only part(s) of the stored value (merging with the existing value)
   */
  const updatePartialLocaleStorage = useCallback(
    (newValue: Partial<T>) => {
      const newObject = { ...value, ...newValue }

      localStorage.setItem(key, JSON.stringify(newObject))

      setValue(newObject)
    },
    [key, value]
  )

  useEffect(() => {
    if (cachedKey.current !== key) {
      cachedKey.current = key

      const newValue = localStorage.getItem(key)

      setValue(newValue ? JSON.parse(newValue) : defaultValue)
    }
  }, [defaultValue, key])

  return [value, updateLocalStorage, updatePartialLocaleStorage] as const
}

export default useLocalStorage
