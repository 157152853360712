import { usePermissionResolution } from '@broker-crm-common'

import {
  GateName,
  Organisation,
  OrganisationType,
  useGetCurrentUserQuery,
  useGetOrganisationQuery,
} from '@acre/graphql'


export const formatAddress = (address: Organisation['primary_address']) => {
  const fields = Object.values(address || {}).filter((value) => value)

  return fields.join(', ')
}

export const useCanManageIntroducers = (introducerOrgId: string) => {
  const { data } = useGetCurrentUserQuery()
  const myOrgId = data?.currentUser?.organisation_id

  const { data: orgData } = useGetOrganisationQuery({ variables: { id: myOrgId! }, skip: !myOrgId })
  const isDAOrg = orgData?.organisation.type === OrganisationType.Da

  const { gates } = usePermissionResolution({
    inputs: introducerOrgId
      ? [{ gates: [GateName.MANAGE_ORGANISATION], entityIdsCollection: { organisation: [introducerOrgId] } }]
      : [],
  })
  return gates.includes(GateName.MANAGE_ORGANISATION) && isDAOrg
}
