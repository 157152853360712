import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface AddressLookupFieldClasses {
  root: string
  field: string
  label: string
  select: string
  helperText: string
  errorText: string
  list: string
  option: string
  placeholder: string
  searchIcon: string
  endAdornment: string
  clearSearchButton: string
}

export type AddressLookupFieldClassKey = keyof AddressLookupFieldClasses

export function getAddressLookupFieldUtilityClass(slot: string) {
  return generateUtilityClass('AddressLookupField', slot)
}

const addressLookupFieldClasses: AddressLookupFieldClasses = generateUtilityClasses('AddressLookupField', [
  'root',
  'field',
  'label',
  'select',
  'helperText',
  'errorText',
  'list',
  'option',
  'placeholder',
  'searchIcon',
  'endAdornment',
  'clearSearchButton',
])

export default addressLookupFieldClasses
