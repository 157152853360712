import React from 'react'
import feather from 'feather-icons'

import { sanitizeHtml } from '@acre/utils'

import { Colour, NewColour, PairedColour, Size } from '../../utils/types'
import { IconName } from './FeatherIcon.types'
import { StyledIcon } from './FeatherIcon.styles'

type CommonProps = {
  name: IconName
  id?: string
  size?: Size
  fill?: boolean
  disabled?: boolean
  overrideColour?: NewColour
}

// If badge is true a PairedColour must be passed in
// Otherwise a colour can be passed in
type TruncatePropsColour =
  | { badge?: false; colour?: Colour }
  | {
      badge?: true
      colour?: PairedColour
    }

// If badge is true a PairedColour must be passed in
// Otherwise a colour can be passed in
type TruncatePropsHover =
  | { hover?: false; hoverColour?: undefined }
  | {
      hover: true
      hoverColour: Colour
    }

export type FeatherIconProps = React.ComponentProps<'div'> & CommonProps & TruncatePropsColour & TruncatePropsHover

const FeatherIcon = ({
  className,
  id,
  name,
  badge,
  colour,
  overrideColour,
  hover,
  hoverColour,
  size = Size.Default,
  fill = false,
  disabled = false,
}: FeatherIconProps) => {
  return (
    <StyledIcon
      className={className}
      badge={badge}
      colour={colour}
      overrideColour={overrideColour}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(feather.icons[name].toSvg()),
      }}
      id={id}
      size={size}
      hover={hover}
      hoverColour={hoverColour}
      filled={fill}
      disabled={disabled}
    />
  )
}

export default FeatherIcon
