import React from 'react'
import { MenuItem } from '@mui/material'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

// import { get } from 'lodash'
import { SelectField, SelectFieldProps } from '@acre/design-system'
// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { type FieldBindingBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'
import type { Option } from '../../shared.types'

export interface SelectFieldRHFProps<T extends FieldValues = FieldValues>
  extends FieldBindingBaseProps<T>,
    SelectFieldProps {
  name: Path<T>
  options: Option[]
}

function _SelectFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: SelectFieldRHFProps<T>,
) {
  const { name, options, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  return (
    <SelectField
      {...other}
      onChange={field.onChange}
      value={field.value}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
    >
      {options?.map((item) => (
        <MenuItem key={item.value.toString()} value={item.value}>
          {parseText && typeof item.label === 'string' ? parseText(item.label) : item.label}
        </MenuItem>
      ))}
    </SelectField>
  )
}

const SelectFieldRHF = React.memo(_SelectFieldRHF) as typeof _SelectFieldRHF

export default SelectFieldRHF
