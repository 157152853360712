import React, { ReactElement, useCallback, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@mui/material'
import { camelCase, isEmpty, startCase } from 'lodash'
import { useIntl } from 'react-intl'
import Select, {
  components,
  IndicatorProps,
  InputProps,
  MenuListComponentProps,
  MenuPlacement,
  MultiValueProps,
  OptionProps,
  ValueType,
} from 'react-select'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { DropdownOption } from '../../utils/types'
import HelperText from '../HelperText'
import Icon, { IconTypes } from '../Icon'
import Label from '../Label'
import LoadingSpinner from '../LoadingSpinner'
import { searchAndSort } from './MultiSearchSelect.helpers'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { selectStyles } from './MultiSearchSelect.styles'

export type MultiSearchSelectProps<D = string | unknown> = {
  id: string
  data: D[]
  label?: string | ReactElement
  selectedValues?: string[] | null
  onChange?: (value: string[]) => any
  mappingFn?:
    | ((value: string, idx: number, array: D[]) => DropdownOption)
    | ((value: unknown, idx: number, array: unknown[]) => DropdownOption)
  error?: boolean
  disabled?: boolean
  loading?: boolean
  message?: string
  customMissingMessage?: string
  placeholder?: string
  isSearchable?: boolean
  openMenuOnClick?: boolean
  closeMenuOnSelect?: boolean
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  showAll?: boolean // set to true if you want to show all of the options as selected
  variant?: Variant
  isPortal?: boolean
  menuPlacement?: MenuPlacement
}

const InputWithAutoComplete = components.Input as React.ComponentType<InputProps & { autoComplete: string }>

const Input = (props: InputProps) => <InputWithAutoComplete {...props} autoComplete="chrome-off" />

const MultiSearchSelect = ({
  id,
  data = [],
  label,
  selectedValues = [],
  onChange,
  mappingFn = (value: string) => ({ value, label: value }),
  error,
  disabled: disabledProp,
  message,
  loading,
  customMissingMessage,
  placeholder,
  isSearchable = true,
  openMenuOnClick = true,
  closeMenuOnSelect = true,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  showAll,
  variant,
  isPortal = false,
  menuPlacement = 'bottom',
}: MultiSearchSelectProps) => {
  const intl = useIntl()
  const theme = useTheme()
  const formatMessage = (id: string, label?: string, less?: string) =>
    intl.formatMessage({ id }, { label, first: label, second: less })

  const disabled = useFieldDisabledState(disabledProp)

  // A component to replace the menu list, this was done to add a test id
  const MenuList = (props: MenuListComponentProps<any, any>) => (
    <components.MenuList {...props}>
      <div data-testid={testHandle(`${id}MenuList`)}>{props.children}</div>
    </components.MenuList>
  )

  // a component to replace the dropdown icon with a custom caret in the select component
  const DropdownIndicator = (props: IndicatorProps<any, any>) => (
    <components.DropdownIndicator {...props}>
      <Icon name={isSearchable ? IconTypes.MagnifyingGlass : IconTypes.DropdownActive} />
    </components.DropdownIndicator>
  )

  const LoadingIndicator = () => {
    return (
      <Box mr={theme.spacers.size8}>
        <LoadingSpinner variant="compact" />
      </Box>
    )
  }

  // remove button icon
  const MultiValueRemove = (props: MultiValueProps<any>) =>
    disabled ? null : (
      <components.MultiValueRemove {...props}>
        <Icon name={IconTypes.Times} />
      </components.MultiValueRemove>
    )

  const Option = (props: OptionProps<any, any>) => {
    return (
      <div
        data-testid={testHandle(`${id}Option${startCase(camelCase(props.children!.toString())).replace(/ /g, '')}`)}
        aria-disabled={props.isDisabled}
      >
        <components.Option {...props}>{props.children}</components.Option>
      </div>
    )
  }

  // reformatting the data to be passed into the select component
  let dataOptions: DropdownOption[] = (data as DropdownOption[]) || []
  let dataValues: string[]

  if (data[0] && typeof data[0] === 'string') {
    dataOptions = (data as string[]).map(mappingFn)
    dataValues = data as string[]
  } else {
    dataValues = (data as DropdownOption[]).map((item) => item.label)
  }

  // this is used for the list of remaining options which can be selected
  const [stateOptions, setStateOptions] = useState<DropdownOption[]>(dataOptions)

  // value is the set of selected items
  const values = dataOptions.filter((o) => selectedValues && selectedValues.includes(o.value))

  // on change the change handler passed in will be triggered after transforming the options to be an array of strings.
  // useCallback is used because this function only needs to be generated again when options (the values which are not selected)
  // or the onChange function changes
  const handleChange = useCallback(
    (values: ValueType<DropdownOption, true>) => {
      const optionsArr = values ? values.map((item) => item && item.value) : []
      onChange && onChange(optionsArr)
    },
    [onChange],
  )

  // collect styles which have theme applied to it
  const styles = selectStyles(theme, variant)

  // search and sort data with input value
  const onInputChange = (inputValue: string) => {
    if (!inputValue) {
      setStateOptions(dataOptions)
    } else {
      const searchAndSorted = searchAndSort(inputValue, [...dataValues]).map(mappingFn)
      const filteredDataOptions = dataOptions.filter((option: DropdownOption) =>
        searchAndSorted.find((sortedOption) => sortedOption.label === option.label),
      )
      setStateOptions(filteredDataOptions)
    }
  }

  // using `value` breaks some components(check doc upload). If needs to be changed to value please test all the places multiselect is used as set up is different
  const defaultValues = !showAll ? values : stateOptions

  return (
    <div data-testid={testHandle(id)}>
      <LabelAndInputWrapper variant={variant}>
        {label ? (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
            variant={variant}
          />
        ) : null}
        <Select
          id={id}
          aria-label={label ? String(label) : id}
          styles={styles}
          isMulti={true} // can have many selected items
          isSearchable={isSearchable} // can search
          isClearable={false}
          isLoading={loading}
          closeMenuOnSelect={closeMenuOnSelect}
          openMenuOnClick={openMenuOnClick}
          tabSelectsValue={false}
          isDisabled={disabled || loading}
          filterOption={() => true} // can override default search
          onInputChange={onInputChange} // match sorter is used for search and sorting using stateOptions
          options={isEmpty(stateOptions) ? dataOptions : stateOptions}
          onChange={handleChange}
          defaultValue={defaultValues}
          placeholder={placeholder}
          menuPortalTarget={isPortal ? document.body : null}
          components={{
            DropdownIndicator,
            MenuList,
            MultiValueRemove,
            Option,
            LoadingIndicator,
            Input,
          }}
          value={values}
          menuPlacement={menuPlacement}
        />
      </LabelAndInputWrapper>
      {message && (
        <HelperText id={id} message={message || ''} textType={error ? HELPER_TYPE_ERROR : ''} variant={variant} />
      )}
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={customMissingMessage || formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </div>
  )
}

export default withDisabled(MultiSearchSelect)
