import omit from 'lodash/omit'

export const trimPhoneNumbers = (obj: any) => {
  const phoneKeys = new Set(['mobile_number', 'phone_number'])

  let newObj = { ...obj }

  for (const key of Object.keys(newObj)) {
    if (phoneKeys.has(key) && typeof newObj[key] === 'string') {
      newObj[key] = newObj[key].slice(-10)
    }
  }

  return newObj
}

export const filterValues = (values: any) => {
  // When comparing phone numbers from values / initial values, we must check the
  // numbers without the prefixes. The BE adds a country code, and comparing the same
  // phone numbers where one contains a country code (and the other doesn't) causes
  // 'pristine' issues on the form (as 0123... !== +44123...)
  const updatedValues = trimPhoneNumbers(values)
  return omit(updatedValues, ['preference_capital_repayment_type', 'preference_repayment_amount'])
}

