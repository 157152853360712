import { type KbaAttempt, KbaStatus } from '@acre/graphql'

const kbaAttempt: KbaAttempt = {
  __typename: 'KbaAttempt',
  attempt_id: 'TEST_ID',
  status: KbaStatus.Success,
  questions: [],
}

export default kbaAttempt
