import { useCallback } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { isBefore } from 'date-fns'
import { isEmpty, isNil, omit } from 'lodash'
import { FormProps } from 'react-final-form'

import {
  addPeriodFixedEndDate,
  CanBeAddedToLoan,
  CreateMortgageMutation,
  currentMortgageProductFeeTypes,
  Maybe,
  Mortgage,
  MortgageInput,
  MortgageProduct,
  MortgageProductActualInput,
  MortgageProductFeeType,
  MortgageStatus,
  omitTypename,
} from '@acre/graphql'

import { calculateTotalFees } from '../../../common/GenericMortgageCard/MortgageProductFees/MortgageProductFees.helpers'
import { MortgageFnArgs, useMutateMortgage } from '../../../common/hooks'
import { RepaymentTypes } from '../../../forms/common/types'
import {
  formatNumberAsBoolean,
  initialRateTiers,
  MortgageState,
  stateToInput,
} from '../../../forms/MortgageFormHelpers/MortgageForm.helpers'

// translation to new fee types
export const missingFieldsFeeTypeDict = {
  [MortgageProductFeeType.DeedsReleaseFee]: 'deeds_release_fee',
  [MortgageProductFeeType.ValuationFee]: 'valuation_fee',
  [MortgageProductFeeType.ArrangementFee]: 'arrangement_fee',
  [MortgageProductFeeType.BookingFee]: 'booking_fee',
  [MortgageProductFeeType.ChapsTelegraphicTransferFee]: 'chaps_fee',
  [MortgageProductFeeType.RedemptionAdminFee]: 'mortgage_discharge_fee',
  [MortgageProductFeeType.LegalFee]: 'disbursement_fee',
}

// If the mortgage is sourced, then get the calculated_values.fees, otherwise get product.fees
export const getMortgageProductFees = (mortgage?: Mortgage | Omit<Mortgage, 'id' | 'mortgage_id'> | null) =>
  mortgage?.raw_results_reference ? mortgage?.calculated_values?.fees : mortgage?.mortgage_product?.fees

/**
 * Function that helps convert fee to a percentage that represents the proportion
 * of the mortgage amount
 * @param mortgageAmount full mortgage amount
 * @param fixedValue value that's smaller than the mortgage (like a fee)
 */
export const getPercentage = (mortgageAmount?: Maybe<string>, fixedValue?: Maybe<string>) => {
  if (!fixedValue || !parseFloat(fixedValue) || !mortgageAmount || !parseFloat(mortgageAmount)) {
    return null
  }

  const floatValue = (parseFloat(fixedValue) / parseFloat(mortgageAmount)) * 100

  // round to 2dp
  const roundedValue = Math.round(floatValue * 100) / 100
  // Times by 100000 to comply with BE representation of percentages
  // As our PercentageInput component deals with that format
  return Math.round(roundedValue * 100000)
}

export const getInvalidProcFeeFields = (mortgage: Parameters<typeof createInitialValues>[0]) => {
  const selectedPercentageFees = mortgage?.mortgage_product?.mortgage_club_proc_fees?.find(
    (fee) => fee.mortgage_club_code === mortgage?.selected_mortgage_club_code,
  )

  const proc_fee_fixed = mortgage?.calculated_values?.calculated_gross_proc_fee

  const net_proc_fee_fixed = mortgage?.calculated_values?.calculated_net_proc_fee

  let invalidFieldGross = null
  if (proc_fee_fixed) {
    invalidFieldGross = 'proc_fee_percentage'
  }
  if (selectedPercentageFees?.proc_fee_percentage || mortgage?.mortgage_product?.proc_fee_percentage) {
    invalidFieldGross = 'proc_fee_fixed'
  }
  let invalidFieldNet = null
  if (net_proc_fee_fixed) {
    invalidFieldNet = 'net_proc_fee_percentage'
  }
  if (selectedPercentageFees?.net_proc_fee_percentage || mortgage?.mortgage_product?.net_proc_fee_percentage) {
    invalidFieldNet = 'net_proc_fee_fixed'
  }

  return {
    invalidFieldGross,
    invalidFieldNet,
  }
}

export const createInitialValues = (
  mortgage?: Mortgage | Omit<Mortgage, 'id' | 'mortgage_id'> | null,
): MortgageState => {
  const product = mortgage?.mortgage_product || {}
  const selectedClub = mortgage?.selected_mortgage_club_code
  const selectedPercentageFees = product?.mortgage_club_proc_fees?.find(
    (fee) => fee.mortgage_club_code === selectedClub,
  )

  const mortgage_club_name = selectedPercentageFees?.mortgage_club_name

  const mortgageAmount = mortgage?.mortgage_amount || '0'
  const net_proc_fee_fixed = mortgage?.calculated_values?.calculated_net_proc_fee
  const net_proc_fee_percentage = getPercentage(mortgageAmount, net_proc_fee_fixed)

  const proc_fee_fixed = mortgage?.calculated_values?.calculated_gross_proc_fee
  const proc_fee_percentage = getPercentage(mortgageAmount, proc_fee_fixed)

  const { invalidFieldGross, invalidFieldNet } = getInvalidProcFeeFields(mortgage)

  const fosCharge = !isNil(mortgage?.first_or_second_charge)
    ? {
      first_or_second_charge: formatNumberAsBoolean(mortgage?.first_or_second_charge || 0),
    }
    : {}

  // Trnasform fixed months into actual end dates if needed
  const ercPeriods = !isNil(product?.early_repayment_charge_periods)
    ? {
      early_repayment_charge_periods: addPeriodFixedEndDate(
        product.early_repayment_charge_periods,
        mortgage?.mortgage_start_date,
      ),
    }
    : {}

  const rateTiers =
    !isNil(product.rate_tiers) && !isEmpty(product.rate_tiers)
      ? addPeriodFixedEndDate(product.rate_tiers, mortgage?.mortgage_start_date)
      : initialRateTiers

  const latestTier = rateTiers && rateTiers[rateTiers.length - 1]
  const endDateExists = !!(rateTiers && latestTier?.period_fixed_end_date)
  const updatedTiers = rateTiers?.map((item, index) => {
    const isLastIndex = rateTiers.length - 1 === index
    if (!endDateExists && isLastIndex) return { ...item, until_end_of_term: true }
    return item
  })

  const { repayment_amount, interest_only_amount, has_arrears } = mortgage || {}

  const allFees = getMortgageProductFees(mortgage)
  const filteredFees =
    mortgage?.status === MortgageStatus.StatusCurrent
      ? allFees?.filter((fee) => Boolean(fee.fee_type) && currentMortgageProductFeeTypes.includes(fee.fee_type!))
      : allFees

  // Must keep this value as a string to keep it consistent with the graphQL type for proc_fee_fixed
  const procFeeFixed = (
    proc_fee_fixed ||
    (proc_fee_percentage &&
      mortgage?.mortgage_amount &&
      ((parseInt(mortgage?.mortgage_amount) / 1000) * proc_fee_percentage) / 10000)
  )?.toString()

  // Must keep this value as a string to keep it consistent with the graphQL type for net_proc_fee_fixed
  const netProcFeeFixed = (
    net_proc_fee_fixed ||
    (net_proc_fee_percentage &&
      mortgage?.mortgage_amount &&
      ((parseInt(mortgage?.mortgage_amount) / 1000) * net_proc_fee_percentage) / 10000)
  )?.toString()

  // Convert any cashback into incentives
  const incentives =
    product?.cashback && product?.cashback !== '0'
      ? [
        {
          type: 'Cashback',
          amount: product.cashback,
        },
      ]
      : undefined

  let cashback = product?.cashback
  let cashback_paid_on = product?.cashback_paid_on

  if (product.cashback === '0' || cashback === null) {
    cashback = undefined
    cashback_paid_on = undefined
  }

  return omitTypename({
    ...mortgage,
    ...product,
    ...fosCharge,
    ...ercPeriods,
    cashback,
    cashback_paid_on,
    repayment_type: getInitialRepaymentType({ repayment_amount, interest_only_amount }),
    rate_tiers: updatedTiers,
    fees: filteredFees,
    fees_total: calculateTotalFees(filteredFees || []),
    incentives,

    // ! Product codes that are longer than 20 chars are usually uuids (that have been set prior to getting this field working)
    // hence no need to display that code if it's longer than 20 chars
    product_code: product.product_code && product.product_code.length <= 20 ? product.product_code : undefined,
    // has_arrears defaults to false on the BE so this is represented on the FE
    has_arrears: has_arrears || false,
    selected_mortgage_club_code: product?.selected_mortgage_club_code || mortgage?.selected_mortgage_club_code,
    // Only £ or % can be set for proc_fee and net_proc_fee, bellow infereid fields is to remember which value was initially set on api
    invalidFieldGross,
    invalidFieldNet,
    net_proc_fee_percentage,
    proc_fee_percentage,
    mortgage_club_name,
    proc_fee_fixed: procFeeFixed,
    net_proc_fee_fixed: netProcFeeFixed,
  })
}

export const isFormDisabled = (mortgage: Mortgage | NewMortgage) => {
  const { lender_proposed, status } = mortgage

  // lender proposed and sourced from our internal sourcing
  if (!lender_proposed && status === MortgageStatus.StatusProposed) {
    return true
  }

  // Users should not be able to amend the recommended mortgage details
  if (status === MortgageStatus.StatusSelected) {
    return true
  }

  return false
}

export type NewMortgage = Omit<Mortgage, 'id' | 'mortgage_id'> & { id?: string }

// if mortgage is lender proposed we should be adding any fees of can_be_added_loan status equal to MUST_BE_ADDED
// to the fees_to_be_added array on a mortgage
export const addFeesToBeAdded = (
  mortgageProductInput: MortgageProductActualInput,
  mortgageInput: MortgageInput,
  isLenderProposed?: Maybe<boolean>,
) => {
  if (!isLenderProposed || !mortgageProductInput?.fees?.length) {
    return null
  }

  const feesToBeAdded: MortgageProductFeeType[] = []
  mortgageProductInput?.fees?.forEach((fee) => {
    if (fee?.can_be_added_to_loan === CanBeAddedToLoan.MustBeAdded && fee?.fee_type) {
      feesToBeAdded.push(fee?.fee_type)
    }
  })

  mortgageInput['fees_to_be_added'] = feesToBeAdded
}

export const useHandleSubmitMortgage = ({ lender_name }: MortgageState) => {
  const { id: caseId, details } = useCaseContext()
  const { preference_target_property, client_ids } = details

  const { handleUpdateMortgage, createMortgage, loading, createdMortgage } = useMutateMortgage({ caseId })

  const handleSubmit = useCallback<FormProps<MortgageState>['onSubmit']>(
    async (values, form) => {
      const productFeeFieldToRemoveNet = values.invalidFieldNet
      const productFeeFieldToRemoveGross = values.invalidFieldGross

      let cleanValues = values

      if (productFeeFieldToRemoveNet && productFeeFieldToRemoveGross) {
        cleanValues = omit(values, [
          productFeeFieldToRemoveNet,
          productFeeFieldToRemoveGross,
          'invalidFieldNet',
          'invalidFieldGross',
        ])
      } else if (productFeeFieldToRemoveNet) {
        cleanValues = omit(values, [productFeeFieldToRemoveNet, 'invalidFieldNet'])
      } else if (productFeeFieldToRemoveGross) {
        cleanValues = omit(values, [productFeeFieldToRemoveGross, 'invalidFieldGross'])
      }

      const isSourcedLenderProposed = values.lender_proposed && values.status === MortgageStatus.StatusProposed

      const { currentMortgageStartDate, outstandingBalanceDate, initialMortgageStartDate } = getMortgageDateValues(
        values,
        form.getState().initialValues,
      )

      // Update outstanding_balance_date to that of mortgage_start_date if mortgage_start_date is changed to a date in the future
      if (
        currentMortgageStartDate &&
        initialMortgageStartDate &&
        !isBefore(currentMortgageStartDate, outstandingBalanceDate as number) &&
        outstandingBalanceDate
      ) {
        cleanValues.outstanding_balance_date = cleanValues.mortgage_start_date
      }

      const { mortgageInput, mortgageProductInput } = stateToInput({ ...cleanValues, lender_name })

      addFeesToBeAdded(mortgageProductInput, mortgageInput, values?.lender_proposed)

      const mortgageFnArgsInitial = stateToInput(form.getState().initialValues)

      let mortgageResponse: Mortgage | undefined | null

      if (!isEmpty(mortgageProductInput)) {
        const mortgageFnArgs: MortgageFnArgs = {
          mortgageInput: {
            ...mortgageInput,
            // Reset the raw_results_reference and source_response if mortgage details of a lender proposed mortgage
            // have been altered
            ...(isSourcedLenderProposed
              ? { raw_results_reference: null, source_response: null, status: MortgageStatus.StatusLenderProposed }
              : {}),
            selected_mortgage_club_code:
              mortgageInput?.selected_mortgage_club_code || mortgageProductInput.selected_mortgage_club_code,
          },
          mortgageProductInput: mortgageProductInput,
        }

        // For lender proposed product, if preference_target_property exists, then link this mortgage to that property
        if (values?.lender_proposed) {
          mortgageFnArgs.mortgageInput.property_secured_ids = preference_target_property
            ? [preference_target_property]
            : []
        }

        // if an id exists for that mortgage than it's an update, otherwise we need to create a new mortgage
        if (values.mortgage_id) {
          const { data } = await handleUpdateMortgage(mortgageFnArgs, values.mortgage_id, mortgageFnArgsInitial)

          mortgageResponse = data?.updateMortgageAndProduct?.mortgage
        } else {
          let variables = {
            mortgageInput: { ...mortgageFnArgs.mortgageInput, client_ids: client_ids },
            mortgageProductInput,
            caseId,
          }

          const optimisticResponse: CreateMortgageMutation = {
            createMortgage: {
              ...(variables.mortgageInput as Mortgage),
              mortgage_product: variables.mortgageProductInput as MortgageProduct,
            },
          }

          const { data } = await createMortgage({ variables, optimisticResponse })

          mortgageResponse = data?.createMortgage
        }
      }

      const resetValues: MortgageState = { ...values, calculated_values: mortgageResponse?.calculated_values }

      form.reset(resetValues)
    },
    [caseId, client_ids, createMortgage, handleUpdateMortgage, lender_name, preference_target_property],
  )

  return {
    handleSubmit,
    loading,
    createdMortgage,
  }
}

export const getInitialRepaymentType = ({
  interest_only_amount,
  repayment_amount,
}: Pick<Mortgage, 'interest_only_amount' | 'repayment_amount'>) => {
  if (repayment_amount && interest_only_amount) {
    if (parseInt(repayment_amount) > 0 && parseInt(interest_only_amount) > 0) {
      return RepaymentTypes.PartAndPart
    }

    if (parseInt(repayment_amount) > 0) {
      return RepaymentTypes.Repayment
    }
    if (parseInt(interest_only_amount) > 0) {
      return RepaymentTypes.InterestOnly
    }
  } else if (repayment_amount && parseInt(repayment_amount) > 0) {
    return RepaymentTypes.Repayment
  }
  if (interest_only_amount && parseInt(interest_only_amount) > 0) {
    return RepaymentTypes.InterestOnly
  }
  // Set repayment_type to null if both interest_only_amount & repayment_amount are
  // empty, or if one is empty and the other is zero
  if (
    (isNil(interest_only_amount) && isNil(repayment_amount)) ||
    (isNil(repayment_amount) && interest_only_amount === '0') ||
    (isNil(interest_only_amount) && repayment_amount === '0')
  ) {
    return null
  }
  return RepaymentTypes.Repayment
}

export const getMortgageDateValues = (currentMortgage: MortgageState, initialMortgage: MortgageState) => {
  const currentMortgageStartDate =
    currentMortgage.mortgage_start_date && Date.parse(currentMortgage.mortgage_start_date)

  const outstandingBalanceDate =
    currentMortgage.outstanding_balance_date && Date.parse(currentMortgage.outstanding_balance_date as string)

  const initialMortgageStartDate =
    initialMortgage.mortgage_start_date && Date.parse(initialMortgage.mortgage_start_date as string)
  return { currentMortgageStartDate, outstandingBalanceDate, initialMortgageStartDate }
}
