import { KbaDecision, type KbaSubmissionResponse } from '@acre/graphql'

const kbaSubmissionResponse: KbaSubmissionResponse = {
  __typename: 'KbaSubmissionResponse',
  attempts_remaining: 0,
  decision: KbaDecision.Pass,
  second_questionnaire: null,
}

export default kbaSubmissionResponse
