import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface RadioFieldGroupClasses {
  root: string
  label: string
  radioGroup: string
  radio: string
  helperText: string
  errorText: string
}

export type RadioFieldGroupClassKey = keyof RadioFieldGroupClasses

export function getRadioFieldGroupUtilityClass(slot: string) {
  return generateUtilityClass('RadioFieldGroup', slot)
}

const radioFieldGroupClasses: RadioFieldGroupClasses = generateUtilityClasses('RadioFieldGroup', [
  'root',
  'label',
  'radioGroup',
  'radio',
  'helperText',
  'errorText',
])

export default radioFieldGroupClasses
