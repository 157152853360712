import { DocumentRequirementInput, Maybe } from '../generated/resolvers'
import request from '../requesters/default'
import { CdmGetDocumentRequirementsResponse } from '../service/luther/model'

// GetDocumentRequirements
// https://crm.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocumentRequirements
export const getDocumentRequirements = async (filterOrganisationIds: string[], requirementDetails: Maybe<boolean>) => {
    const response = await request.get<CdmGetDocumentRequirementsResponse>(
        `/requirement?filter_organisation_ids=${filterOrganisationIds}&requirement_details=${requirementDetails ?? true}`,
    )
    return response?.data
}

// AddDocumentRequirement
// https://crm.acreplatforms.co.uk/api_documentation/redoc.html#operation/CreateDocumentRequirement
export const addDocumentRequirements = async (input: DocumentRequirementInput) => {
    const response = await request.post<CdmGetDocumentRequirementsResponse>(`/requirement`, {
        document_requirement: input,
    })
    return response?.data
}

// UpdateDocumentRequirement
// https://crm.acreplatforms.co.uk/api_documentation/redoc.html#operation/UpdateDocumentRequirement
export const updateDocumentRequirements = async (
    input: DocumentRequirementInput,
) => {
    const response = await request.patch<CdmGetDocumentRequirementsResponse>(
        `/requirement/${input.requirement_id}`,
        input,
    )
    return response?.data
}