import { UIEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import type { AutocompleteInputChangeReason, AutocompleteProps, AutocompleteValue } from '@mui/material'
import { isEqual } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import type { AutocompleteFieldAsyncMode } from './AutocompleteField.types'

export interface UseAsyncAutocomplete<
  Option,
  QueryVariables extends Record<string, unknown> | string = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
> {
  defaultValue?: AutocompleteValue<Option, boolean, boolean, boolean>
  skip?: boolean
  mode: AutocompleteFieldAsyncMode
  loadData: (args?: QueryVariables) => Promise<QueryResults | undefined>
  loadMore?: (args: MoreQueryVariables) => Promise<MoreQueryResults | undefined>
  getOptions?: (data: QueryResults | MoreQueryResults) => Option[]
  searchThrottleTimeout?: number
  multiple?: boolean
  getQueryVariables?: (args: { value?: string | number; cursor: number }) => QueryVariables | MoreQueryVariables
  getPageCursor?: (response: QueryResults | MoreQueryResults) => number | null
}

const useAsyncAutocomplete = <
  Option,
  QueryVariables extends Record<string, unknown> | string = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
>(
  props: UseAsyncAutocomplete<Option, QueryVariables, MoreQueryVariables, QueryResults, MoreQueryResults>,
) => {
  const {
    defaultValue,
    loadData,
    loadMore,
    getOptions,
    mode = 'static',
    skip = false,
    searchThrottleTimeout = 250,
    multiple = false,
    getQueryVariables,
    getPageCursor,
  } = props

  const optionsCacheRef = useRef<Option[]>([])
  const queryVariablesRef = useRef<QueryVariables | MoreQueryVariables>()
  const pageCursorRef = useRef(0)
  const valueRef = useRef<string | number>()

  const [options, setOptions] = useState<Option[]>(optionsCacheRef.current)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')

  const load = useCallback(async () => {
    const queryVars = getQueryVariables?.({ cursor: pageCursorRef.current, value: valueRef.current })

    if (!optionsCacheRef.current?.length || !isEqual(queryVariablesRef.current, queryVars)) {
      queryVariablesRef.current = queryVars

      setLoading(true)

      const data = await loadData(queryVariablesRef.current as QueryVariables ?? undefined)

      if (data) {
        optionsCacheRef.current = getOptions?.(data) ?? (data as Option[])
        pageCursorRef.current = getPageCursor?.(data) as number
      }

      setLoading(false)
    }

    setOptions(optionsCacheRef.current as Option[])
  }, [getOptions, getPageCursor, getQueryVariables, loadData])

  // Determines whether to lazy load or not: -
  // - Search mode: Always
  // - Static mode: Only if no default value is provided
  const shouldLazyLoad = mode === 'search' || (mode === 'static' && !defaultValue)

  useEffect(() => {
    if (!skip && !shouldLazyLoad && !optionsCacheRef.current?.length && !loading) {
      load()
    }
  }, [defaultValue, load, loading, shouldLazyLoad, skip])


  const handleOpen = useCallback(async () => {
    if (mode === 'search') {
      setOptions((prev) => (prev?.length ? [] : prev))
    }

    if (mode === 'static') {
      setOpen(true)

      if (shouldLazyLoad) {
        load()
      } else {
        setOptions(optionsCacheRef.current)
      }
    }
  }, [load, mode, shouldLazyLoad])

  const handleClose = useCallback(() => {
    setOpen(false)
    setOptions([])
  }, [])

  const onScroll = useCallback<UIEventHandler<HTMLUListElement>>(
    async (event) => {
      if (!loadMore) return

      const listboxNode = event.currentTarget

      if (
        listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
        pageCursorRef.current != options.length &&
        Boolean(pageCursorRef.current)
      ) {
        if (getQueryVariables && pageCursorRef.current != null) {
          const data = await loadMore(
            getQueryVariables({ cursor: pageCursorRef.current, value: valueRef.current }) as MoreQueryVariables
          )

          if (data) {
            optionsCacheRef.current = getOptions?.(data) ?? (data as Option[])
            pageCursorRef.current = getPageCursor?.(data) as number

            // push new options to the existing list
            setOptions((prev) => prev.concat(getOptions?.(data) ?? (data as Option[])))
          }
        }
      }
    },
    [getOptions, getPageCursor, getQueryVariables, loadMore, options.length],
  )

  const handleClearAll = useCallback(() => {
    setInputValue('')

    if (props.mode === 'search') {
      setOptions([])
    }
  }, [props.mode])

  // Used when: mode === 'search'
  const onInputChangeSearch_Internal = useDebouncedCallback(
    async (
      _event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'input') {
        if (!value && !multiple) {
          handleClose()
          return
        }

        setLoading(true)
        setOptions([])
        setOpen(true)

        // Reset page cursor on first search
        const data = await loadData((getQueryVariables?.({ cursor: 0, value }) || value) as QueryVariables)

        if (data) {
          // Use options length instead of cursor because the cache can return more
          const optionsData = (getOptions?.(data) ?? data) as Option[]

          pageCursorRef.current = getPageCursor?.(data) ?? optionsData.length
          valueRef.current = value as string

          setOptions(optionsData)
        }

        setLoading(false)

        return
      }

      if (reason === 'reset' || reason === 'clear') {
        setOptions((prev) => {
          return !multiple || !prev?.length ? [] : prev
        })

        return
      }
    },
    searchThrottleTimeout,
    { leading: false },
  )

  const onInputChangeSearch = useCallback(
    async (
      _event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'input' || (reason === 'reset' && !multiple)) {
        setInputValue(value)
      } else if (reason === 'reset' || reason === 'clear') {
        setInputValue('')
      }

      onInputChangeSearch_Internal(_event, value, reason)
    }, [multiple, onInputChangeSearch_Internal]
  )

  // used when input mode is 'static'
  const onInputChangeStatic = useCallback<
    Required<AutocompleteProps<Option, boolean, boolean, boolean>>['onInputChange']
  >((e, value) => {
    setInputValue(value)
  }, [])

  const onChange = useCallback<Required<AutocompleteProps<Option, boolean, boolean, boolean>>['onChange']>(
    (event, value, reason) => {
      if ((multiple && reason === 'removeOption') || reason === 'clear') {
        if ((value as [])?.length === 0) {
          handleClose()
        }
      }
    },
    [handleClose, multiple],
  )

  if (skip) return

  if (mode === 'static') {
    return {
      waiting: !shouldLazyLoad && !optionsCacheRef.current?.length,
      fieldProps: {
        open,
        options,
        loading,
        inputValue,
        onOpen: handleOpen,
        onClose: handleClose,
        onInputChange: onInputChangeStatic,
        onChange: undefined,
      },
      listboxProps: {
        onScroll,
        onClearAll: handleClearAll,
      },
    }
  }

  if (mode === 'search') {
    return {
      waiting: false,
      fieldProps: {
        open,
        options,
        loading,
        inputValue,
        onInputChange: onInputChangeSearch,
        onChange,
        onOpen: handleOpen,
        onClose: handleClose,
      },
      listboxProps: {
        onScroll,
        onClearAll: handleClearAll,
      },
    }
  }
}

export default useAsyncAutocomplete
