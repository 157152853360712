import { FetchPolicy } from '@apollo/client'

import {
  CaseStatus,
  GetCasePreferencesDocument,
  GetCaseVerificationsDocument,
  GetMortgageProductDocument,
  GetPropertyDocument,
  Maybe,
} from '@acre/graphql'

export const getRefetchQueries = (caseId?: string, caseStatus?: Maybe<CaseStatus>, propertyId?: string) => {
  const refetchQueries = []

  if (caseId && CaseStatus.Lead !== caseStatus) {
    refetchQueries.push({
      query: GetCaseVerificationsDocument,
      variables: { id: caseId },
      fetchPolicy: 'network-only' as FetchPolicy,
    })
    refetchQueries.push({
      query: GetCasePreferencesDocument,
      variables: { id: caseId },
    })
  }

  if (caseId) {
    refetchQueries.push({
      query: GetMortgageProductDocument,
      variables: { id: caseId },
      fetchPolicy: 'network-only' as FetchPolicy,
    })
  }

  if (propertyId) {
    refetchQueries.push({
      query: GetPropertyDocument,
      variables: { id: propertyId },
    })
  }

  return refetchQueries
}
