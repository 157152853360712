import { omit } from 'lodash'

import {
  CoverAmountType,
  FamilyIncomeBenefits,
  IncomeProtection,
  LifeAndCriticalIllness,
  NewFee,
  PrivateMedicalInsurance,
  RequirementInput,
  RequirementType,
  WholeOfLife,
} from '../generated/resolvers'
import { CdmFee, CdmFeeFeeType } from '../service/luther'
import {
  convertPenceToPounds,
  convertPercentageToLutherFormat,
  convertPoundsToPence,
  convertToPercentageValue,
} from '../utils/formatNumber'

const isFeeFixed = (fee_type: CdmFeeFeeType) =>
  Boolean(
    fee_type === 'LEGAL_COMPLETION_FIXED' ||
    fee_type === 'LEGAL_EXCHANGE_FIXED' ||
    fee_type === 'OFFER_FIXED' ||
    fee_type === 'PREADVICE_FIXED' ||
    fee_type === 'PREAPPLICATION_FIXED' ||
    fee_type === 'PRERECOMMENDATION_FIXED',
  )

export const formatFeeAsLuther = ({ fee_type, value }: NewFee) => {
  let valueFormatted = value

  // If it's a fixed amount, format it to the frontend standard of £1 = 100
  if (isFeeFixed(fee_type)) {
    valueFormatted = parseFloat(convertPenceToPounds(value) || '0').toFixed(2)
  } else {
    valueFormatted = convertPercentageToLutherFormat(value)
  }

  return {
    fee_type,
    value: valueFormatted,
  }
}

export const formatFeeAsAcre = ({ fee_type, value }: CdmFee) => {
  let valueFormatted = value

  // If it's a fixed amount, format it to the frontend standard of 100 = £1
  if (fee_type && isFeeFixed(fee_type)) {
    valueFormatted = convertPoundsToPence(value) as string
  } else {
    valueFormatted = convertToPercentageValue(value)
  }

  return {
    fee_type,
    value: valueFormatted,
  }
}

/**
 * Updates an array of requirements by replacing a value based on a specific ID, or appending the value if no ID is provided.
 *
 * @param {Array} requirements - The original array of requirements.
 * @param {number | string | undefined} id - The unique identifier for the requirement. If undefined, the value will be appended.
 * @param {any} value - The new value to be inserted or appended into the requirements array.
 *
 * @returns {Array} A new array that includes the updated requirements.
 */

export const termOrAgeUpdated = (
  initialRequirements:
    | LifeAndCriticalIllness[]
    | FamilyIncomeBenefits[]
    | IncomeProtection[]
    | WholeOfLife[]
    | PrivateMedicalInsurance[],
  currentValue: RequirementInput,
) => {
  const activeInitialRequirement = initialRequirements?.find((requirement) => requirement.id === currentValue.id)

  if (
    !(activeInitialRequirement && ('term_years' in activeInitialRequirement || 'term_age' in activeInitialRequirement))
  ) {
    return false
  }

  return (
    activeInitialRequirement?.term_years !== currentValue.term_years ||
    activeInitialRequirement?.term_age !== currentValue.term_age
  )
}

export const updateRequirements = (
  requirements:
    | LifeAndCriticalIllness[]
    | FamilyIncomeBenefits[]
    | IncomeProtection[]
    | WholeOfLife[]
    | PrivateMedicalInsurance[],
  value: RequirementInput,
  requirement_type: RequirementType,
  id?: number | string | null,
) => {
  // If we are changing from monthly to maximum for cover_amount_type
  // we need to remove the current selected id requirement and make a new one
  const isTermOrAgeUpdated = termOrAgeUpdated(requirements, value as {})

  // The user must be editting an existing requirement if an
  // id exists and term or age fields are updated
  if (id !== undefined && isTermOrAgeUpdated) {
    const index = requirements.findIndex((requirement) => requirement.id === id)

    const firstPart = requirements.slice(0, index)
    const secondPart = requirements.slice(index + 1)

    const filteredValue = omit(value, 'id')

    return [...firstPart, filteredValue, ...secondPart]
  }

  if (id !== undefined && requirement_type === RequirementType.IncomeProtection) {
    // if id is present and we are changing cover_amount_type to maximum, it means that there
    // already is product with id and cover_amount in database
    // if we send cover_amount as null, BE will think there is nothing to update, keeping
    // both maximum and cover_amount with value, which is unsuporrted
    if (value.cover_amount_type === CoverAmountType.Maximum) {
      const index = requirements.findIndex((requirement) => requirement.id === id)

      const firstPart = requirements.slice(0, index)
      const secondPart = requirements.slice(index + 1)

      const filteredValue = omit(value, 'id')

      return [...firstPart, filteredValue, ...secondPart]
    }
  }

  // if there is no selected id, we just add the value to the requirements as new
  if (id === undefined) {
    return [...(requirements || []), value]
  }
  //otherwise replace it
  return requirements.map((requirement) => (requirement.id === id ? value : requirement))
}
