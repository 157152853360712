import { GraphQLError } from 'graphql'

import { UserLoader } from '../api'
import { getDocumentVerification } from '../api/document'
import { getDocumentVerificationCp } from '../api_client_portal/document'
import { DocumentVerification, DocumentVerificationCp, Resolvers } from '../generated/resolvers'

const resolvers: Resolvers = {
  Query: {
    documentVerifications: async (_, { documentId, params }) => {
      const { verifications } = await getDocumentVerification(documentId, params)
      return verifications as DocumentVerification[]
    },
    documentVerificationsCp: async (_, { documentId, params }) => {
      const { verifications } = await getDocumentVerificationCp(documentId, params)

      return verifications as DocumentVerificationCp[]
    },
  },
  Document: {
    verifications: async ({ document_id }) => {
      if (document_id) {
        const { verifications } = await getDocumentVerification(document_id, {})
        return verifications as DocumentVerification[]
      } else {
        return []
      }
    },
  },
  DocumentVerification: {
    source_user: async ({ source_user_id }) => {
      if (!source_user_id) {
        return null
      }

      const user = await UserLoader.load(source_user_id)

      if (user instanceof GraphQLError) {
        console.error(user)
        throw user
      }

      return user
    }
  }
}

export default resolvers
