import { format } from 'date-fns'
import { GraphQLError } from 'graphql'
import { omit } from 'lodash'

import {
  addMortgageToCase,
  addMortgageToProduct,
  createMortgage,
  createMortgageHelper,
  createMortgageWithProductAndLinkToCase,
  fetchMortgageByVersion,
  fetchProperty,
  getProductListByProductCodeAndLender,
  MortgageProductLoader,
  sourceMortgages,
  sourceProductBySourcingProductId,
  updateMortgageAndProduct,
  updateMortgageHelper,
  updateMortgages,
} from '../api'
import {
  associateMortgageWithProductCP,
  createMortgageCP,
  createMortgageProductCP,
  MortgageProductLoaderCp,
  updateMortgageCP,
} from '../api_client_portal'
import { Mortgage, MortgageStatus, PropertyVersion, Resolvers } from '../generated/resolvers'
import {
  formatGetAcreMortgageProductResponseParentAsMortgageProductResponse,
  formatSourcedMortgageProductResponseList,
} from '../utils/schemaMapping/mortgageProduct'
import deleteMortgageHelper from './helpers/deleteMortgageHelper'
import { getEndDate, getPropertyValue, getProposedStartDate } from './util'

const resolvers: Resolvers = {
  Query: {
    mortgageVersion: async (_parent, { id, version }) => await fetchMortgageByVersion({ mortgage_id: id, version }),
    getMortgageProductCodes: async (_parent, { isBtl, lender, productCode }) =>
      await getProductListByProductCodeAndLender(isBtl, productCode?.toUpperCase(), lender),
    getMortgageProductBySourcingProductId: async (_parent, { input }) => {
      const { isBtl, sourcingProductId, loanAmount, valuationAmount, isCurrent } = input
      return await sourceProductBySourcingProductId(isBtl, sourcingProductId, loanAmount, valuationAmount, isCurrent)
    },
  },
  Mutation: {
    sourceMortgages: async (_parent, { input }) => {
      const response = await sourceMortgages(input)
      const { products } = response
      return {
        ...response,
        products: products ? formatSourcedMortgageProductResponseList(products) : null,
      }
    },

    addMortgageAndProductCp: async (_parent, { mortgageInput, mortgageProductInput }) => {
      const { status, term, term_unit, mortgage_start_date } = mortgageInput
      const defaultStartDate =
        mortgageInput.status === MortgageStatus.StatusCurrent
          ? format(new Date(), 'yyyy-MM-dd')
          : getProposedStartDate(status || MortgageStatus.InvalidStatus)
      const startDate = mortgage_start_date || defaultStartDate
      const prepopulatedMortgageInput = {
        ...mortgageInput,
        interest_only_amount: mortgageInput.interest_only_amount || '0',
        mortgage_start_date: startDate,
        mortgage_end_date: getEndDate(startDate, term, term_unit),
      }
      const newMortgage = await createMortgageCP(prepopulatedMortgageInput)

      if (!newMortgage) {
        return null
      }

      const newProduct = await createMortgageProductCP(mortgageProductInput)

      await associateMortgageWithProductCP({
        product_id: newProduct.product_id,
        mortgage_id: newMortgage.id,
      })

      if (newProduct.product_id) {
        const product = await MortgageProductLoaderCp.load(newProduct.product_id)

        if (product) {
          const mortgage = {
            ...newMortgage,
            id: newMortgage.mortgage_id,
            mortgage_product_id: newProduct.product_id,
          }

          try {
            return { ...mortgage, mortgage_product: product }
          } catch (e) {
            console.error(e)
            return mortgage
          }
        }
      }

      return null
    },

    saveMortgageProductToCase: async (_parent, { input }) => {
      const {
        status,
        clientIds,
        case_id,
        repayment_amount,
        product_code,
        source_response,
        mortgage_amount,
        term,
        term_unit,
        initial_monthly_payment,
        property_secured_ids,
        preference_target_property_value,
        part_investment_loan_required_investment_part,
      } = input

      let property: PropertyVersion | GraphQLError | null = null

      if (property_secured_ids && property_secured_ids[0]) {
        property = await fetchProperty(property_secured_ids[0])

        if (property instanceof GraphQLError) {
          throw property
        }
      }

      const startDate =
        status === MortgageStatus.StatusCurrent
          ? format(new Date(), 'yyyy-MM-dd')
          : getProposedStartDate(status || MortgageStatus.InvalidStatus)

      const newMortgage = await createMortgage({
        client_ids: clientIds,
        term: term,
        term_unit,
        status,
        mortgage_amount,
        repayment_amount,
        property_secured_ids,
        monthly_payment: initial_monthly_payment ? initial_monthly_payment.toString() : null,
        interest_only_amount: part_investment_loan_required_investment_part
          ? part_investment_loan_required_investment_part
          : '0',
        property_value: getPropertyValue(
          property,
          status || MortgageStatus.InvalidStatus,
          preference_target_property_value || '',
        ),
        mortgage_start_date: startDate,
        mortgage_end_date: getEndDate(startDate, term, term_unit),
      })

      if (!newMortgage) {
        return null
      }

      const { product_id } = await addMortgageToProduct({
        product_code,
        source_response,
        mortgage_id: newMortgage.id,
      })

      if (product_id) {
        await addMortgageToCase({
          case_id,
          mortgage_id: newMortgage.mortgage_id,
        })
        const product = await MortgageProductLoader.load(product_id)

        if (product) {
          const mortgage = {
            ...newMortgage,
            id: newMortgage.mortgage_id,
            mortgage_product_id: product_id,
          }

          // We use a try catch in case the 'source_response' is improperly formatted
          // If that is the case, we'll just return the mortgage without it
          try {
            const formattedSourceResponse = source_response ? JSON.parse(source_response) : null
            return { ...mortgage, source_response: formattedSourceResponse }
          } catch (e) {
            console.error(e)
            return mortgage
          }
        }
      }

      return null
    },
    createMortgage: async (_parent, { caseId, mortgageInput, mortgageProductInput }) => {
      const input = { caseId, mortgageInput, mortgageProductInput }
      const mortgageFormatted = await createMortgageHelper(input)
      return mortgageFormatted
    },
    updateMortgage: async (_parent, { id, input }) => {
      const mortgage = await updateMortgageHelper(id, input)
      if (!mortgage) return
      return {
        ...mortgage,
        id: String(mortgage.id),
      } as Mortgage
    },
    updateMortgageCp: async (_parent, { id, input }) => {
      const mortgage = await updateMortgageCP(id, input)
      return mortgage
    },
    updateMortgages: async (_parent, { input }) => {
      if (!input?.length) {
        throw new GraphQLError('No mortgages provided for update', {
          extensions: {
            status: 400,
          },
        })
      }
      const mortgages = await updateMortgages(input)
      return mortgages || []
    },
    deleteMortgage: async (_parent, { mortgageId, caseId, isPropertyPortfolio }) => {
      return await deleteMortgageHelper(mortgageId, caseId, isPropertyPortfolio)
    },
    deleteMortgages: async (_parent, { mortgageIds, caseId, isPropertyPortfolio }) => {
      return await Promise.all(
        mortgageIds.map(async (id) => await deleteMortgageHelper(id, caseId, isPropertyPortfolio)),
      )
    },
    createMortgageWithProductAndLinkToCase: async (_, { input }) => {
      return await createMortgageWithProductAndLinkToCase(input)
    },
    updateMortgageAndProduct: async (_, { input }) => {
      if (!input?.mortgage_id) {
        throw new GraphQLError('Mortgage ID is required for update', {
          extensions: {
            status: 400,
          },
        })
      }
      return await updateMortgageAndProduct(input.mortgage_id, omit(input, 'mortgage_id'))
    },
  },
  Mortgage: {
    mortgage_product: async (parent: Mortgage) => {
      const { mortgage_product_id } = parent
      if (!mortgage_product_id) return null

      const response = await MortgageProductLoader.load(mortgage_product_id)
      if (!response) return null
      return formatGetAcreMortgageProductResponseParentAsMortgageProductResponse(response)
    },
    mortgage_productCp: async (parent: Mortgage) => {
      const { mortgage_product_id } = parent
      if (!mortgage_product_id) return null

      const response = await MortgageProductLoaderCp.load(mortgage_product_id)
      if (!response) return null
      return formatGetAcreMortgageProductResponseParentAsMortgageProductResponse(response)
    },
  },
}

export default resolvers
