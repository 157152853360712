import type { AllFieldProps } from '../Field.types'

const useAriaProps = (props: AllFieldProps) => {
  const { id, name, error, helperText, label, hideLabel } = props

  const ariaId = id ?? name?.replace(/\./g, '-')

  const testId = props['data-testid'] || `e2e-${ariaId}`
  const descriptionId = helperText ? `${ariaId}-helper-text` : ''
  const errorId = error ? `${ariaId}-error` : ''
  const labelId = label ? `${ariaId}-label` : ''

  const fieldAriaProps = {
    'aria-describedby': descriptionId,
    'aria-errormessage': errorId,
    'aria-invalid': !!errorId,

    ...(hideLabel && typeof label === 'string' ? ({
      'aria-label': label
    }) : ({
      'aria-labelledby': labelId
    }))
  }



  return { ariaId, testId, descriptionId, errorId, labelId, fieldAriaProps }
}

export default useAriaProps
