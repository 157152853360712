import React from 'react'
import type { ArrayPath, FieldPath, FieldValues, Path } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import type { FormComponentSchema } from '../../types/schema'
import type {
  ComponentMapBase,
  ComponentSchemaPropsMapBase,
  FormCustomContext,
  InferPropsMap,
} from '../../types/schema-base'
import type { FieldTypes } from '../../types/types'
import Field, { FieldProps } from '../Field'
import useFormSchema from '../../hooks/useFormSchema'
import FieldWithData, { FieldWithDataProps } from '../../features/dataProvider/FieldWithData'
import getFieldSchemaByName from '../../util/getFieldSchemaByName'

export type SchemaFormComponentProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues>,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TComponentPropsMap extends ComponentSchemaPropsMapBase = InferPropsMap<TComponentMap>,
> = FormComponentSchema<TFieldValues, TComponentPropsMap, TContext, TName>

const _SchemaFormComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues> = Path<TFieldValues>,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
>(
  props: SchemaFormComponentProps<TFieldValues, TName, TComponentMap, TContext>,
) => {
  const { blockIndex, schema } = useFormSchema<TContext, TFieldValues, TComponentMap>()

  const fieldSchema =
    blockIndex && getFieldSchemaByName<TFieldValues, InferPropsMap<TComponentMap>, TContext>(blockIndex, props.name)

  if (!fieldSchema) {
    console.error(`Schema not found for ${props.name}`)
    return null
  }

  if (fieldSchema && fieldSchema.data !== undefined) {
    const dataMap: FieldWithDataProps<TContext, TFieldValues>['dataMap'] = new Map()

    // I have no idea why this is necessary but TS is complaining about the type of fieldSchema.data
    // Casting it here is the best that Claude & I could come up with
    const fieldData = fieldSchema.data as NonNullable<typeof fieldSchema.data>

    if (Array.isArray(fieldSchema.data)) {
      fieldSchema.data.forEach((dataSchema) => {
        const adapter = schema?.dataProviders?.find((provider) => provider.key === dataSchema?.key)

        if (adapter) {
          dataMap.set(adapter, dataSchema)
        }
      })
    } else if ('key' in fieldData) {
      const adapter = schema?.dataProviders?.find((provider) => provider.key === fieldData.key)

      if (adapter) {
        dataMap.set(adapter, fieldData)
      }
    }

    if (dataMap.size) {
      return (
        <FieldWithData<TContext, TFieldValues>
          dataMap={dataMap}
          fieldProps={props as FieldProps<keyof FieldTypes, never, TFieldValues>}
        />
      )
    }
  }

  if (fieldSchema.type === 'fieldgroup' && '_components' in fieldSchema && Array.isArray(fieldSchema._components)) {
    const children = fieldSchema._components.map((componentSchema) => (
      <SchemaFormComponent<TFieldValues, FieldPath<TFieldValues>, TComponentMap, TContext>
        key={componentSchema.name as string}
        {...componentSchema}
      />
    ))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Field<keyof FieldTypes, any, AnyObjectSchema, TFieldValues> {...props}>{children}</Field>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Field<keyof FieldTypes, any, AnyObjectSchema, TFieldValues> {...props} />
}

const SchemaFormComponent = React.memo(_SchemaFormComponent) as typeof _SchemaFormComponent

export default SchemaFormComponent
