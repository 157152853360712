import React, { ForwardedRef, forwardRef, ReactElement, useCallback, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import classNames from 'classnames'
import { camelCase, startCase } from 'lodash'
import { matchSorter } from 'match-sorter'
import { useIntl } from 'react-intl'
import Select, { ActionMeta, components, InputProps, MenuPlacement } from 'react-select'
import { IndicatorProps } from 'react-select/src/components/indicators'
import { MenuListComponentProps } from 'react-select/src/components/Menu'
import { OptionProps } from 'react-select/src/components/Option'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledContext from '../../hooks/useFieldDisabledContext'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { DropdownOption } from '../../utils/types'
import HelperText from '../HelperText'
import Icon, { IconTypes } from '../Icon'
import Label from '../Label'
import LoadingSpinner from '../LoadingSpinner'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { selectStyles, SingleSearchSelectWrapper } from './SingleSelectSearch.styles'

export type SingleSearchSelectProps = {
  id: string
  options: DropdownOption[]
  label?: string | ReactElement
  value?: string | null
  onChange?: (value: string, meta?: ActionMeta<DropdownOption>) => any
  onBlur?: () => any
  error?: boolean
  disabled?: boolean
  message?: string
  placeholder?: string
  isMissing?: boolean
  isIncomplete?: boolean
  isClearable?: boolean
  verificationError?: string
  mapOptionsToSelectFormat?: boolean
  variant?: Variant
  controlShouldRenderValue?: boolean
  focusDefaultOption?: boolean
  highlightSelected?: boolean
  isPortal?: boolean
  isLoading?: boolean
  menuPlacement?: MenuPlacement
}

const InputWithAutoComplete = components.Input as React.ComponentType<InputProps & { autoComplete: string }>

const Input = (props: InputProps) => <InputWithAutoComplete {...props} autoComplete="chrome-off" />

const SingleSearchSelect = forwardRef(function SingleSearchSelect(
  {
    id,
    options = [],
    label,
    value,
    onChange,
    onBlur,
    error,
    disabled,
    message,
    placeholder,
    isMissing = false,
    isIncomplete = false,
    isClearable = true,
    verificationError,
    variant = 'default',
    controlShouldRenderValue = true,
    focusDefaultOption = true,
    highlightSelected = true,
    isPortal = false,
    isLoading = false,
    menuPlacement = 'bottom',
  }: SingleSearchSelectProps,
  ref: ForwardedRef<any>,
) {
  const intl = useIntl()
  const theme = useTheme()
  const formatMessage = (id: string): string => intl.formatMessage({ id })
  const contextDisabled = useFieldDisabledContext()
  const wrapperClassName = classNames({ error })

  // A component to replace the menu list, this was done to add a test id
  const MenuList = (props: MenuListComponentProps<any, any>) => (
    <components.MenuList {...props}>
      <div data-testid={testHandle(`${id}MenuList`)}>{props.children}</div>
    </components.MenuList>
  )

  // A component to replace the dropdown icon with a custom caret in the select component
  const DropdownIndicator = (props: IndicatorProps<any, any>) => (
    <components.DropdownIndicator {...props}>
      <Icon name={IconTypes.MagnifyingGlass} />
    </components.DropdownIndicator>
  )

  // A component to replace the clear indicator icon with a custom text in the select component
  const ClearIndicator = (props: IndicatorProps<any, any>) => {
    const {
      children = 'clear',
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles('clearIndicator', props)}
        data-testid={testHandle(`${id}Clear`)}
      >
        <div>{children}</div>
      </div>
    )
  }

  const Option = (props: OptionProps<any, any>) => {
    return (
      <div
        data-testid={testHandle(`${id}Option${startCase(camelCase(props.children!.toString())).replace(/ /g, '')}`)}
        aria-disabled={props.isDisabled}
      >
        <components.Option {...props}>{props.children}</components.Option>
      </div>
    )
  }

  // find the default value among the options
  const defaultValue = options.find(({ value: val }) => val === value)

  // this is used for the list of remaining options which can be selected
  const [stateOptions, setStateOptions] = useState<DropdownOption[]>(options || [])

  // refresh local state options once options prop has changed
  useEffect(() => {
    setStateOptions(options)
  }, [options])

  // on change the change handler passed in will be triggered after transforming the options to be an array of strings.
  // useCallback is used because this function only needs to be generated again when options (the values which are not selected)
  // or the onChange function changes

  const handleChange = useCallback(
    (value: DropdownOption | readonly DropdownOption[] | null | undefined, action: ActionMeta<DropdownOption>) => {
      onChange && onChange(value ? (value as DropdownOption).value : '', action)
    },
    [onChange],
  )

  // collect styles which have theme applied to it
  const styles = selectStyles(theme, variant, highlightSelected)

  // search and sort data with input value
  const onInputChange = (inputValue: string) => {
    if (!inputValue) {
      setStateOptions(options)
    } else {
      // search and sort by the label (names, not codes)
      const searchAndSorted = matchSorter(options, inputValue, {
        keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'label' }],
      })
      setStateOptions(searchAndSorted)
    }
  }

  const LoadingIndicator = () => <LoadingSpinner variant="compact" />

  const isDisabled = disabled !== undefined ? disabled : contextDisabled

  return (
    <SingleSearchSelectWrapper data-testid={testHandle(id)} className={wrapperClassName} ref={ref}>
      <LabelAndInputWrapper variant={variant}>
        {label && (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
          />
        )}
        <Select
          id={id}
          aria-label={label ? String(label) : id}
          styles={styles}
          isClearable={isClearable}
          tabSelectsValue={false}
          isDisabled={isDisabled}
          onInputChange={onInputChange} // match sorter is used for search and sorting using stateOptions
          onBlur={onBlur}
          options={stateOptions}
          classNamePrefix="react-select"
          onChange={handleChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autocomplete="false" // disables autocomplete that overflows on top of select options
          components={{
            DropdownIndicator,
            MenuList,
            ClearIndicator,
            Option,
            Input,
            LoadingIndicator,
          }}
          isLoading={isLoading}
          // @ts-ignore - need to pass in empty string (otherwise upon passing null, the old value persists on screen), issue with react-select
          value={value ? defaultValue : ''}
          focusDefaultOption={focusDefaultOption}
          controlShouldRenderValue={controlShouldRenderValue}
          menuPortalTarget={isPortal ? document.body : null}
          menuPlacement={menuPlacement}
        />
      </LabelAndInputWrapper>
      {message && (
        <HelperText id={id} message={message || ''} textType={error ? HELPER_TYPE_ERROR : ''} variant={variant} />
      )}
      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </SingleSearchSelectWrapper>
  )
})

export default withDisabled(SingleSearchSelect)
