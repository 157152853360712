import type { FieldValues } from "react-hook-form"
import type { FormComponentSchema } from "../types/schema"
import type { ComponentSchemaPropsMapBase, FormCustomContext } from "../types/schema-base"

const indexArrayPartBlocks = <
  TFieldValues extends FieldValues,
  CPropsMap extends Partial<ComponentSchemaPropsMapBase> = ComponentSchemaPropsMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFormComponentSchema extends FormComponentSchema<TFieldValues, CPropsMap, TContext> = FormComponentSchema<TFieldValues, CPropsMap, TContext>,
>(
  parentName: string,
  blocks: TFormComponentSchema[],
): Record<string, TFormComponentSchema> => {
  let index: Record<string, TFormComponentSchema> = {}

  for (const block of blocks) {
    if ('_components' in block && block._components) {
      index = Object.assign(index, indexBlocks<TFieldValues, CPropsMap, TContext>(block._components))
    }

    if ('parts' in block && block.parts) {
      index = Object.assign(index, indexArrayPartBlocks<TFieldValues, CPropsMap, TContext>(`${parentName}.${block.name}`, block.parts as FormComponentSchema<TFieldValues, CPropsMap, TContext>[]))
    }

    index[`${parentName}.${block.name}`] = block

  }

  return index
}


const indexBlocks = <
  TFieldValues extends FieldValues,
  CPropsMap extends Partial<ComponentSchemaPropsMapBase> = ComponentSchemaPropsMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFormComponentSchema extends FormComponentSchema<TFieldValues, CPropsMap, TContext> = FormComponentSchema<TFieldValues, CPropsMap, TContext>,
>(
  blocks: TFormComponentSchema[],
): Record<string, TFormComponentSchema> => {
  let index: Record<string, TFormComponentSchema> = {}

  for (const block of blocks) {
    if ('_components' in block && block._components) {
      index = Object.assign(index, indexBlocks<TFieldValues, CPropsMap, TContext>(block._components))
    }

    if ('parts' in block && block.parts) {
      index = Object.assign(index, indexArrayPartBlocks<TFieldValues, CPropsMap, TContext>(block.name, block.parts as FormComponentSchema<TFieldValues, CPropsMap, TContext>[]))
    }

    index[block.name] = block

  }

  return index
}

export default indexBlocks