import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { format, parse } from 'date-fns'
import { camelCase, isEqual, unionWith } from 'lodash'

import {
  GetArOrganisationsMiQuery,
  GetCaseOwnersQuery,
  GetLenderNamesQuery,
  GetOrganisationQuery,
  LedgerType,
  MortgageReason,
  Period,
  ReportsPaymentStatus,
  ReportsViewBy,
  ReportsViewDateBy,
  RevenueReportsType,
} from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

import { ProductTypes } from '../fields/Case/sharedFields'
import { LEDGER_PAYMENT_TYPE_MAP } from '../pages/Cases/CaseDetails/CaseLedgers/CaseLedger.helpers'

export type ReportsContextStateType = {
  showViewByModal: boolean
  showFilterBar: boolean
  showThresholdView: boolean
  caseTypeOptions: Array<{ label: string; value: MortgageReason }>
  paymentStatusOptions: DropdownOption[]
  revenueTypeOptions: { label: string; value: LedgerType }[]
  viewDateByOptions: Array<{ label: string; value: string }>
  predictedRevenueTypeOptions: Array<{ label: string; value: string }>
  caseOwners?: GetCaseOwnersQuery | undefined
  arOrgs?: GetArOrganisationsMiQuery | undefined
  lenderNames?: GetLenderNamesQuery | undefined
  periodSettings?: GetOrganisationQuery['organisation']['periods_settings'] | undefined
}

export type ReportsContextType = {
  state?: ReportsContextStateType
  setState?: Dispatch<SetStateAction<ReportsContextStateType>>
}

// Acre currently does not support ReasonEquityRelease and ReasonFurtherAdvance case types
// they should only be available if the case in the system already exists with those types selected

let ViewDateByOptions = [
  {
    label: `mi.viewDateBy.${camelCase(ReportsViewDateBy.CaseCreatedAt)}`,
    value: ReportsViewDateBy.CaseCreatedAt,
  },
  {
    label: `mi.viewDateBy.${camelCase(ReportsViewDateBy.LedgerCreatedAt)}`,
    value: ReportsViewDateBy.LedgerCreatedAt,
  },
  {
    label: `mi.viewDateBy.${camelCase(ReportsViewDateBy.ReceivedDate)}`,
    value: ReportsViewDateBy.ReceivedDate,
  },
]

let CaseTypeOptions = unionWith(
  [...ProductTypes],
  [
    {
      label: 'productTypes.noReasonProvided',
      value: MortgageReason.InvalidMortgageReason,
    },
    {
      label: 'productTypes.furtherAdvance',
      value: MortgageReason.ReasonFurtherAdvance,
    },
  ],
  (a, b) => isEqual(a.value, b.value),
)

const PredictedRevenueTypeOptions = [
  {
    label: `mi.predictedRevenueTypes.${camelCase(RevenueReportsType.Revenue)}`,
    value: RevenueReportsType.Revenue,
  },
  {
    label: `mi.predictedRevenueTypes.${camelCase(RevenueReportsType.PredictedRevenue)}`,
    value: RevenueReportsType.PredictedRevenue,
  },
]

const revenueTypeCombinedCache: { [name: string]: true } = {}

const revenueTypeOptions = (Object.keys(LEDGER_PAYMENT_TYPE_MAP || {}) as LedgerType[]).sort().reduce(
  (acc, key: LedgerType) => {
    if (key.endsWith('PERCENTAGE')) {
      return acc
    }

    if (key.endsWith('COMBINED')) {
      revenueTypeCombinedCache[`${key.slice(0, -8)}FIXED`] = true
    }

    if (revenueTypeCombinedCache[key]) {
      return acc
    }

    acc.push({
      label: LEDGER_PAYMENT_TYPE_MAP[key],
      value: key,
    })

    return acc
  },
  [] as { label: string; value: LedgerType }[],
)

const paymentStatusOptions: DropdownOption[] = [
  {
    label: 'accounting.statuses.DUE',
    value: ReportsPaymentStatus.Outstanding,
  },
  {
    label: 'accounting.statuses.COLLECTED',
    value: ReportsPaymentStatus.Collected,
  },
]

export const defaults: ReportsContextType = {
  state: {
    showViewByModal: false,
    showFilterBar: true,
    showThresholdView: true,
    caseTypeOptions: CaseTypeOptions,
    paymentStatusOptions,
    revenueTypeOptions,
    viewDateByOptions: ViewDateByOptions,
    predictedRevenueTypeOptions: PredictedRevenueTypeOptions,
    caseOwners: undefined,
    lenderNames: undefined,
  },
}

export const ReportsContext = createContext<ReportsContextType>(defaults)

export default ReportsContext

export const useReportsContext = () => useContext(ReportsContext)

export const getAdvisorOptions = (caseOwners: GetCaseOwnersQuery | undefined) => {
  return caseOwners?.caseOwners?.map((advisor) => ({
    value: advisor.id!,
    label: `${advisor.first_name} ${advisor.last_name}`,
  }))
}

export const getArOrgsOptions = (arOrgs: GetArOrganisationsMiQuery | undefined) =>
  arOrgs?.organisation?.arOrganisationsMi?.organisations?.length
    ? arOrgs?.organisation?.arOrganisationsMi?.organisations.reduce<{ label: string; value: string }[]>((acc, org) => {
        if (org.name && org.organisation_id) {
          return [
            ...acc,
            {
              label: org.name,
              value: org.organisation_id,
            },
          ]
        }
        return acc
      }, [])
    : []

export const getPeriodOptions = (periods?: Period[] | null) => {
  return periods?.map((period) => ({
    value: period.id!,
    label: period.name!,
    periodStartDate: period.start_date,
    periodEndDate: period.end_date,
  }))
}

export const getLenderOptions = (lenderNames: GetLenderNamesQuery | undefined) => {
  return lenderNames?.lenderNames?.map((lender) => ({
    value: lender.id!,
    label: lender.name!,
  }))
}

export const getName = (
  value?: string | null | undefined,
  viewBy?: string | null | undefined,
  context?: ReportsContextType,
  formatMessage?: (id: string, values?: any) => string,
) => {
  if (viewBy === ReportsViewBy.Advisor) {
    const advisor = context?.state?.caseOwners?.caseOwners?.find((advisor) => advisor.id === value)
    return advisor ? `${advisor.first_name} ${advisor.last_name}` : null
  }

  if (viewBy === ReportsViewBy.Lender) {
    return context?.state?.lenderNames?.lenderNames?.find((lender) => lender.id === value)?.name
  }

  if (viewBy === ReportsViewBy.CaseType) {
    const label = context?.state?.caseTypeOptions?.find((caseType) => caseType.value === value)?.label
    return label && formatMessage ? formatMessage(label) : null
  }

  if (viewBy === ReportsViewBy.Introducer) {
    return null
  }

  if (viewBy === ReportsViewBy.YearMonth) {
    return value ? format(parse(value, 'yyyy-M', new Date()), 'MMM yy') : ''
  }

  if (viewBy === ReportsViewBy.LedgerType) {
    let translationKey = value ? LEDGER_PAYMENT_TYPE_MAP[value as keyof typeof LEDGER_PAYMENT_TYPE_MAP] : ''

    if (value && value?.endsWith('Percentage')) {
      translationKey = translationKey + '%'
    }

    return translationKey && typeof formatMessage === 'function' ? formatMessage(translationKey) : translationKey
  }
}
