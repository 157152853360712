import { useRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

import useFormApi from './useFormApi'

const useFormDirtyState = () => {
  const { control } = useFormContext()
  const { ephemerals } = useFormApi()

  const { isDirty, dirtyFields, } = useFormState({ control })

  const serialisedDirtyFieldRef = useRef('')
  const isDirtyRef = useRef(isDirty)

  const serialisedDirtyFields = Object.keys(dirtyFields).join('.')

  if (!serialisedDirtyFields.length) {
    isDirtyRef.current = false
  } else if (serialisedDirtyFieldRef.current !== serialisedDirtyFields || isDirtyRef.current !== isDirty) {
    serialisedDirtyFieldRef.current = serialisedDirtyFields

    if (ephemerals?.length) {
      const dirtyFieldNames = Object.keys(dirtyFields)

      for (const fieldName of dirtyFieldNames) {
        if (ephemerals.includes(fieldName)) {
          continue
        }

        isDirtyRef.current = true
        break
      }
    } else {
      isDirtyRef.current = isDirty
    }
  }

  return isDirtyRef.current
}

export default useFormDirtyState
