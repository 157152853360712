import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  ChipProps,
  ChipTypeMap,
  Typography,
  unstable_composeClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Button from '../ButtonNewest/Button'
import CheckboxField from '../CheckboxField/CheckboxField'
import Chip from '../Chip/Chip'
import FeatherIcon from '../FeatherIcon/FeatherIcon'
import { IconName } from '../FeatherIcon/FeatherIcon.types'
import type { ExtendField, FieldProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import TextField, { TextFieldProps } from '../TextField/TextField'
import { AutocompleteFieldAsyncMode, AutocompleteOption } from './AutocompleteField.types'
import autocompleteFieldClasses, { getAutocompleteFieldUtilityClass } from './autoCompleteFieldClasses'
import AutocompleteFieldListBox from './AutocompleteFieldListBox'
import useAsyncAutocomplete from './useAsyncAutocomplete'

export interface AutocompleteFieldProps<
  Option extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> | string = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
> extends FieldProps,
    ExtendField<Omit<AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'>> {
  ChipProps?: ChipProps<ChipComponent>
  asyncMode?: AutocompleteFieldAsyncMode
  getOptions?: (data: QueryResults | MoreQueryResults | Option[]) => Option[]
  getQueryVariables?: (args: { value?: string | number; cursor: number }) => QueryVariables | MoreQueryVariables
  getPageCursor?: (response: QueryResults | MoreQueryResults) => number | null
  loadData?: (args?: QueryVariables) => Promise<QueryResults | undefined>
  loadMore?: (args: MoreQueryVariables) => Promise<MoreQueryResults | undefined>
  options?: Option[]
  windowed?: boolean
  searchDebounceTimeout?: number
  checkbox?: boolean
  multiControls?: boolean
  TextFieldProps?: Omit<TextFieldProps, 'name' | 'label'>
  optionClassName?: string
}

const AutocompleteFieldRoot = styled(Autocomplete, {
  name: 'AutocompleteField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({ theme, disabled, loading }) => ({
  '@keyframes pulse': {
    '0%, 100%': {
      backgroundColor: '#fff',
    },
    '50%': {
      backgroundColor: '#e9e9ea',
    },
  },

  [`.${autocompleteClasses.inputRoot}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),

    ...(disabled && {
      background: 'rgb(245, 246, 250)',
    }),

    ...(loading && {
      animation: 'pulse 2s infinite',
    }),

    [`& .${autocompleteFieldClasses.select}`]: {
      background: 'transparent',
      flex: 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
    },

    [`& .${autocompleteFieldClasses.searchIcon}`]: {
      '& svg': {
        stroke: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
})) as typeof Autocomplete

const AutocompleteEndAdornment = styled('div', {
  name: 'AutocompleteField',
  slot: 'EndAdornment',
  overridesResolver: (props, styles) => styles.endAdornment,
})({
  // We use a position absolute to support wrapping tags.
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(0, -50%)',
  display: 'flex',
})

const AutocompleteClearSearchButton = styled(Button, {
  name: 'AutocompleteField',
  slot: 'EndAdornment',
  overridesResolver: (props, styles) => styles.clearSearchButton,
})({
  marginRight: 12,
})

const useUtilityClasses = <
  Option extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
>(
  ownerState: Partial<
    AutocompleteFieldProps<
      Option,
      Multiple,
      DisableClearable,
      FreeSolo,
      any,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    >
  >,
) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    select: ['select'],
    helperText: ['helperText'],
    errorText: ['errorText'],
    list: ['list'],
    option: ['option'],
    placeholder: ['placeholder'],
    searchIcon: ['searchIcon'],
    endAdornment: ['endAdornment'],
    clearSearchButton: ['clearSearchButton'],
  }

  return unstable_composeClasses(slots, getAutocompleteFieldUtilityClass, ownerState.classes)
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function _AutocompleteField<
  Option extends AutocompleteOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
>(
  props: AutocompleteFieldProps<
    Option,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent,
    QueryVariables,
    MoreQueryVariables,
    QueryResults,
    MoreQueryResults
  >,
) {
  const {
    className,
    name,
    label,
    error,
    helperText,
    WrapperProps,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    TextFieldProps,
    ListboxProps,
    placeholder = 'Select an item...',
    layout = 'row',
    size = 'medium',
    value,
    options: optionsProp,
    renderOption: renderOptionProp,
    asyncMode = 'static',
    loadData,
    loadMore,
    getOptions,
    getQueryVariables,
    getPageCursor,
    onChange,
    isOptionEqualToValue: isOptionEqualToValueProp,
    searchDebounceTimeout,
    checkbox,
    multiControls,
    clearText = 'Clear',
    responsive,
    optionClassName,
    ...rootProps
  } = props

  if (!loadData && !optionsProp) {
    console.error('You must provide either `options` or `loadData` prop')
  }

  if (loadData && optionsProp) {
    console.warn(
      'Providing both `options` & `loadData` props will result in `options` being ignored. Is this what you want?',
    )
  }

  const slotClasses = useUtilityClasses({ ...props, layout, size, disabled: props.disabled })

  const { ariaId, labelId, fieldAriaProps } = useAriaProps(props)

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  const asyncOptions = useAsyncAutocomplete<Option, QueryVariables, MoreQueryVariables, QueryResults, MoreQueryResults>(
    {
      defaultValue: value,
      skip: !loadData,
      mode: asyncMode,
      loadData: loadData!,
      loadMore,
      getOptions,
      getQueryVariables,
      getPageCursor,
      searchThrottleTimeout: searchDebounceTimeout,
      multiple: !!props.multiple,
    },
  )

  const { fieldProps: asyncFieldProps, listboxProps: asyncListboxProps, waiting } = asyncOptions || {}

  const { onClearAll: asyncOnClearAll, ...listboxProps } = asyncListboxProps || {}

  const initialValueHandledRef = useRef(!waiting && !(asyncMode === 'search' && value))

  const options = useMemo(() => {
    // Handle saved value for search fields
    if (asyncMode === 'search' && value && typeof value !== 'string' && !initialValueHandledRef.current) {
      return (asyncFieldProps?.options.length ? [...asyncFieldProps.options, value] : [value]) as Option[]
    }

    if (loadData) {
      return asyncFieldProps?.options
    }

    if (!optionsProp) {
      return []
    }

    return getOptions?.(optionsProp) ?? optionsProp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncFieldProps?.options, getOptions, loadData, optionsProp])

  const optionFromValue = useMemo<AutocompleteValue<Option, boolean, DisableClearable, FreeSolo>>(() => {
    if (asyncMode === 'search') {
      return null as AutocompleteValue<Option, boolean, DisableClearable, FreeSolo>
    }

    if (props.multiple) {
      return Array.isArray(value)
        ? value.reduce(
            (acc, val) => {
              const option = options?.find((option) => option.value === val)

              if (option) {
                acc.push(option)
              }

              return acc
            },
            [] as AutocompleteValue<Option, true, DisableClearable, FreeSolo>,
          )
        : ([] as AutocompleteValue<Option, true, DisableClearable, FreeSolo>)
    }

    return (
      options?.length && (typeof value === 'string' || typeof value === 'number')
        ? options?.find((option) => option.value === value)
        : value
    ) as AutocompleteValue<Option, false, DisableClearable, FreeSolo>
  }, [asyncMode, options, props.multiple, value])

  useEffect(() => {
    if (initialValueHandledRef.current) {
      return
    }

    if (asyncMode === 'search') {
      setFieldValue(value as AutocompleteValue<Option, false, DisableClearable, FreeSolo>)
      initialValueHandledRef.current = true
      return
    }

    if (options?.length && optionFromValue) {
      setFieldValue(optionFromValue)
      initialValueHandledRef.current = true
    }
  }, [asyncMode, optionFromValue, options, options?.length, value, waiting])

  const resetValue = useRef<AutocompleteValue<Option, boolean, DisableClearable, FreeSolo>>(props.multiple ? [] : null)

  const [fieldValue, setFieldValue] = React.useState<AutocompleteValue<
    Option,
    boolean,
    DisableClearable,
    FreeSolo
  > | null>((initialValueHandledRef.current && optionFromValue) || resetValue.current)

  const [inputValue, setInputValue] = React.useState('')

  const handleInputChange = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo>>['onInputChange']
  >((event, newInputValue) => {
    setInputValue(newInputValue)
  }, [])

  const getOptionLabel = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo>>['getOptionLabel']
  >(
    (value) => {
      if (value && rootProps.getOptionLabel) {
        return rootProps.getOptionLabel(value)
      }

      const options = asyncFieldProps?.options || optionsProp

      const label =
        typeof value === 'number' || typeof value === 'string'
          ? options?.find((option) => option.value === value)?.label
          : value.label

      return label ?? ''
    },
    [asyncFieldProps?.options, optionsProp, rootProps],
  )

  const isOptionEqualToValue = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo>>['isOptionEqualToValue']
  >(
    (option, value) => {
      if (isOptionEqualToValueProp) {
        return isOptionEqualToValueProp(option, value)
      }

      if (typeof value === 'object' && 'value' in value) {
        return option.value === value.value
      }

      return option.value === value
    },
    [isOptionEqualToValueProp],
  )

  const renderOption = useMemo(() => {
    const defaultRenderOption: Required<
      AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>
    >['renderOption'] = (props2: { key?: string } & React.HTMLAttributes<HTMLLIElement>, option, { selected }) => {
      const { key, ...otherProps } = props2

      return (
        <li key={key} {...otherProps} className={classnames(optionClassName, otherProps.className, slotClasses.option)}>
          {checkbox && (
            <CheckboxField name={`${name}-${option.value}`} icon={icon} checkedIcon={checkedIcon} checked={selected} />
          )}
          {getOptionLabel?.(option) ?? option.label}
        </li>
      )
    }

    return renderOptionProp
      ? (props: React.HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) =>
          renderOptionProp({ ...props, className: classnames(optionClassName, slotClasses.option) }, option, state)
      : defaultRenderOption
  }, [renderOptionProp, optionClassName, slotClasses.option, checkbox, name, getOptionLabel])

  const asyncOnChange = asyncFieldProps?.onChange

  const handleChange = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>>['onChange']
  >(
    (...args) => {
      setFieldValue(args[1])

      if (asyncOnChange) {
        asyncOnChange(...args)
      }

      if (onChange) {
        onChange(...args)
      }
    },
    [asyncOnChange, onChange],
  )

  const handleSelectAll = useCallback(() => {
    if (options?.length) {
      setFieldValue(options)
    }
  }, [options])

  const handleClearAll = useCallback(() => {
    setFieldValue(resetValue.current)

    if (asyncOnClearAll) {
      asyncOnClearAll?.()
    } else {
      setInputValue('')
    }

    onChange?.(
      {} as SyntheticEvent,
      resetValue.current as AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>,
      'clear',
    )
  }, [asyncOnClearAll, onChange])

  const handleClearInput = useCallback(() => {
    setFieldValue(resetValue.current)

    if (asyncOnClearAll) {
      asyncOnClearAll?.()
    } else {
      setInputValue('')
    }

    if (asyncMode === 'search') {
      asyncFieldProps?.onClose()
    }

    onChange?.(
      {} as SyntheticEvent,
      resetValue.current as AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>,
      'clear',
    )
  }, [asyncFieldProps, asyncMode, asyncOnClearAll, onChange])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const disabled = props.disabled || props.loading || (asyncFieldProps?.loading && !asyncFieldProps?.open)

  const renderTags = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo>>['renderTags']
  >(
    (tagValue, getTagProps) => {
      const tagLimit = props.limitTags || 3

      if (tagValue.length > tagLimit) {
        return <Typography>{`${tagValue.length} Selected`}</Typography>
      } else {
        return tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })

          return (
            <Chip key={key} label={option.label} {...tagProps} size={props.size === 'large' ? 'medium' : 'small'} />
          )
        })
      }
    },
    [props.limitTags, props.size],
  )

  return (
    <AutocompleteFieldRoot
      {...rootProps}
      // @ts-expect-error - MUI types are incorrect for custom components
      ListboxComponent={AutocompleteFieldListBox}
      ListboxProps={{
        ...ListboxProps,
        className: classnames(ListboxProps?.className, slotClasses.list),
        // @ts-expect-error - MUI types are incorrect for custom components
        multiControls,
        value,
        selectedCount: Array.isArray(fieldValue) ? fieldValue?.length : undefined,
        optionCount: options?.length,
        onClearAll: handleClearAll,
        onSelectAll: handleSelectAll,
        ...listboxProps,
      }}
      value={fieldValue as AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>}
      renderTags={rootProps.renderTags ?? renderTags}
      renderOption={renderOption}
      id={ariaId}
      autoComplete
      disableCloseOnSelect={!!props.multiple}
      isOptionEqualToValue={isOptionEqualToValue}
      clearText={clearText}
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          label={label}
          inputRef={inputRef}
          layout={layout}
          helperText={helperText}
          name={name}
          size={props.size}
          responsive={responsive}
          placeholder={isEmpty(fieldValue) ? placeholder : ''}
          error={error}
          InputLabelProps={{ ...labelProps, ...TextFieldProps?.InputLabelProps, id: labelId }}
          FormHelperTextProps={{ ...helperTextProps, ...TextFieldProps?.FormHelperTextProps }}
          ErrorTextProps={{ ...errorProps, ...TextFieldProps?.ErrorTextProps }}
          WrapperProps={{ ...WrapperProps, ...TextFieldProps?.WrapperProps }}
          inputProps={{
            ...params.inputProps,
            ...fieldAriaProps,
            ...TextFieldProps?.inputProps,
            className: classnames(
              TextFieldProps?.inputProps?.className,
              params.inputProps.className,
              slotClasses.select,
            ),
          }}
          className={classnames(TextFieldProps?.className, slotClasses.field)}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
            startAdornment: (
              <>
                {asyncMode === 'search' ? (
                  <>
                    <FeatherIcon className={slotClasses.searchIcon} name={IconName.Search} />
                    {params.InputProps.startAdornment}
                  </>
                ) : (
                  params.InputProps.startAdornment
                )}
              </>
            ),
            endAdornment:
              !props.disableClearable && asyncMode === 'search'
                ? (asyncFieldProps?.inputValue || fieldValue) && (
                    <AutocompleteEndAdornment className={slotClasses.endAdornment}>
                      <AutocompleteClearSearchButton
                        variant="text"
                        color="secondary"
                        onClick={handleClearInput}
                        disabled={rootProps.loading || asyncFieldProps?.loading}
                      >
                        {clearText}
                      </AutocompleteClearSearchButton>
                    </AutocompleteEndAdornment>
                  )
                : params.InputProps.endAdornment,
          }}
        />
      )}
      {...asyncFieldProps}
      loading={rootProps.loading || asyncFieldProps?.loading}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      options={options || []}
      onChange={handleChange}
      className={classnames(slotClasses.root, className)}
      inputValue={asyncFieldProps?.inputValue !== undefined ? asyncFieldProps?.inputValue : inputValue}
      onInputChange={asyncFieldProps?.onInputChange !== undefined ? asyncFieldProps?.onInputChange : handleInputChange}
    />
  )
}

const AutocompleteField = React.memo(_AutocompleteField) as typeof _AutocompleteField

export default AutocompleteField
