import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { Feature, FlagType, useFlag, useUserContext } from '@acre/utils'
import { GateName } from '@acre/graphql'
import { HoverPopOver, NavLinkMenuWrapper, StyledDashboardsNavButton } from '@acre/design-system'

import DashboardsNavPopoverItem from './DashboardsNavPopoverItem'

type Props = {
  gates: GateName[]
}

const DashboardsNavPopover = ({ gates }: Props) => {
  const { pathname } = useLocation()
  const user = useUserContext()

  // Control the display of performance dashboards links
  const dashboardsFlag = useFlag([FlagType.Feature, Feature.Dashboards], false)
  // Control the display of network dashboards links, both performance network and consumer duty network
  const networkFlag = useFlag([FlagType.Feature, Feature.Network], false)

  const hasViewMiDashboardPermission = gates.includes(GateName.VIEW_MI_DASHBOARD)

  // Hard coded the styling,
  // if any condition changes in the future need to double check the styling.
  const shouldRenderSingleColumn = !dashboardsFlag || !hasViewMiDashboardPermission

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null)

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleMouseLeave = useCallback(() => {
    // Start a 400ms timer to close popover
    hoverTimerRef.current = setTimeout(() => setAnchorEl(null), 400)
  }, [])

  const handleMouseEnter = useCallback(() => {
    // Clear the timer if the mouse re-enters before 400ms
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current)
      }
    }
  }, [])

  return (
    <>
      <StyledDashboardsNavButton
        data-testid="e2e-DashboardsButton"
        onMouseEnter={handleOpen}
        onSelect={handleClose}
        active={pathname.includes('/dashboard/')}
        href="#"
        open={!!anchorEl}
      >
        <FormattedMessage id="nav.dashboards" />
      </StyledDashboardsNavButton>
      <HoverPopOver
        interactive
        id="dashboardsPopover"
        onMouseEnter={handleMouseEnter}
        onClose={handleMouseLeave}
        anchorEl={anchorEl}
        placement="bottom-start"
        offsetY={4}
      >
        <NavLinkMenuWrapper shouldRenderSingleColumn={shouldRenderSingleColumn}>
          {dashboardsFlag && (
            <DashboardsNavPopoverItem
              to={`/dashboard/performance/user/${user?.id}`}
              id="PerformanceUser"
              pathname={pathname}
              iconColour="violet"
              onClick={handleClose}
            />
          )}
          <DashboardsNavPopoverItem
            to={`/dashboard/compliance/user/${user?.id}`}
            id="ComplianceUser"
            pathname={pathname}
            iconColour="violet"
            onClick={handleClose}
          />
          {dashboardsFlag && hasViewMiDashboardPermission && (
            <DashboardsNavPopoverItem
              to={`/dashboard/performance/company/${user?.organisation_id}`}
              id="PerformanceCompany"
              pathname={pathname}
              iconColour="orange"
              onClick={handleClose}
            />
          )}
          {hasViewMiDashboardPermission && (
            <DashboardsNavPopoverItem
              to={`/dashboard/compliance/company/${user?.organisation_id}`}
              id="ComplianceCompany"
              pathname={pathname}
              iconColour="orange"
              onClick={handleClose}
            />
          )}
          {dashboardsFlag && networkFlag && hasViewMiDashboardPermission && (
            <DashboardsNavPopoverItem
              to={`/dashboard/performance/network/${user?.organisation?.representative_of}`}
              id="PerformanceNetwork"
              pathname={pathname}
              iconColour="turquoise"
              onClick={handleClose}
            />
          )}
          {networkFlag && hasViewMiDashboardPermission && (
            <DashboardsNavPopoverItem
              to={`/dashboard/compliance/network/${user?.organisation?.representative_of}`}
              id="ComplianceNetwork"
              pathname={pathname}
              iconColour="turquoise"
              onClick={handleClose}
            />
          )}
        </NavLinkMenuWrapper>
      </HoverPopOver>
    </>
  )
}

export default DashboardsNavPopover
