import configs from '@acre/config'

import {
  GeneralInsuranceInput,
  GeneralInsuranceProvider,
  GetGeneralInsuranceProductsInput,
  GetGeneralInsuranceQuoteResponse,
  GetGeneralInsuranceQuotesResponse,
  InputMaybe,
  Maybe,
} from '../generated/resolvers'
import request from '../requesters/default'
import giRequest from '../requesters/generalInsurance'
import {
  AcreProcessorAddGeneralInsuranceProductCaseBody,
  CdmCreateGeneralInsuranceProductRequest,
} from '../service/luther/model'

const { CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs

export enum GiProvider {
  Uinsure = 'UINSURE',
  Paymentshield = 'PAYMENTSHIELD',
}

export type GiCheckAuthBody = {
  encrypted_username: string
  encrypted_password: string
  provider: GeneralInsuranceProvider
}

export const createGeneralInsuranceProduct = async (input: CdmCreateGeneralInsuranceProductRequest) => {
  const response = await request.post('/general_insurance_product', input)
  return response?.data
}

export const updateGeneralInsuranceProduct = async (id: string, input: CdmCreateGeneralInsuranceProductRequest) => {
  const response = await request.patch(`/general_insurance_product/${id}`, input)
  return response?.data
}

export const addGeneralInsuranceProductCase = async (
  general_insurance_product_id: string,
  input: AcreProcessorAddGeneralInsuranceProductCaseBody,
) => {
  const response = await request.post(`/general_insurance_product/${general_insurance_product_id}/case`, input)
  return response?.data
}

export const removeGeneralInsuranceProduct = async (general_insurance_product_id: string, case_id: string) => {
  const response = await request.delete(`/general_insurance_product/${general_insurance_product_id}/case/${case_id}`)
  return response?.data
}

export const getGeneralInsuranceProduct = async (input: InputMaybe<GetGeneralInsuranceProductsInput>, useClientApi: Maybe<boolean>) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseApiUrl =
    useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  const axiosOpts = useClientApi ? { baseURL: `${baseApiUrl}`, params: input } : { params: input }

  const response = await request.get('/general_insurance_product', axiosOpts)
  return response?.data
}

export const fetchQuotes = async (input: InputMaybe<GeneralInsuranceInput>) => {
  const response = await giRequest.post<GetGeneralInsuranceQuotesResponse>('/quotes', input)
  return response?.data
}

export const fetchQuote = async (case_id: string, quote_id: string) => {
  const response = await giRequest.get<GetGeneralInsuranceQuoteResponse>(`/quotes/${case_id}/${quote_id}`)
  return response?.data
}

export const checkAuthGi = async (body: GiCheckAuthBody): Promise<{ credentials_valid: boolean } | undefined> => {
  const response = await giRequest.post('/check_auth', body)
  return response?.data
}
