import { inputBaseClasses, OutlinedInput, outlinedInputClasses, styled } from '@mui/material'

import type { TextFieldBaseProps } from './TextFieldBase.types'

const TextFieldBase = styled(OutlinedInput, {
  name: 'TextField',
  slot: 'Root',
  overridesResolver: (props, styles) => [styles.root, styles[props.mode]],
  shouldForwardProp: (prop) => prop !== 'mode',
})<TextFieldBaseProps>(({ mode = 'editable', theme }) => ({
  flexGrow: 1,

  input: {
    height: theme.spacing(4),
    borderRadius: 'inherit',
    padding: `0 ${theme.spacing(2)}`,
  },

  [`&.${inputBaseClasses.multiline}`]: {
    padding: theme.spacing(2),
  },

  [`input, &.${inputBaseClasses.multiline}`]: {
    background: theme?.palette.background.paper ?? 'white',
    width: '100%',
  },

  [`&.${outlinedInputClasses.adornedEnd}, &.${outlinedInputClasses.adornedStart}`]: {
    background: theme?.palette.background.paper ?? 'white',
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme?.colours?.baseLight ?? 'grey',
  },

  [`&:hover:not(div.${outlinedInputClasses.disabled},div.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: theme?.palette.primary.main,
    },

  [`&.${outlinedInputClasses.disabled} input`]: {
    background: '#F5F6FA',

    [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: '#d7dae0',
    },

    ...((mode === 'protected' || mode === 'visible') && {
      background: 'transparent',
      '-webkit-text-fill-color': theme?.palette.text.primary,
      color: theme?.palette.text.primary,

      [`& ~ .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: 'transparent',
      },
    }),
  },

  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: '1px!important',
  },
}))

export default TextFieldBase
