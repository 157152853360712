
import { addDocumentRequirements, getDocumentRequirements, updateDocumentRequirements } from '../api/document_requirement'
import { } from '../api_client_portal/document'
import { DocumentRequirement, Resolvers } from '../generated/resolvers'

const DocumentRequirementResolvers: Resolvers = {
  Query: {
    documentRequirements: async (_, { filter_organisation_ids, requirement_details }) => {
      const data = await getDocumentRequirements(filter_organisation_ids, requirement_details)
      return data.document_requirements as DocumentRequirement[]
    },
  },
  Mutation: {
    addDocumentRequirement: async (_, { input }) => {
      const data = await addDocumentRequirements(input)
      return data.document_requirements as DocumentRequirement[]
    },
    updateDocumentRequirement: async (_, { input }) => {
      const data = await updateDocumentRequirements(input)
      return data.document_requirements as DocumentRequirement[]
    },
  },
}

export default DocumentRequirementResolvers
