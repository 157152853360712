import React, { useCallback } from 'react'
import { type AutocompleteValue, type ChipTypeMap, Typography } from '@mui/material'
import type { AnyObjectSchema } from 'yup'
import { AddressLookupField, AddressLookupFieldProps, AddressLookupOption, Chip } from '@acre/design-system'
import { useController, Path, FieldValues, get } from 'react-hook-form'

// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { useFieldValidationSchema, type FieldBindingBaseProps, useFormApi, useFormContext } from '@acre/forms'
import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'

export interface AddressLookupFieldRHFProps<
  Option extends AddressLookupOption,
  Details extends Record<string, unknown> = Option,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryResults extends Record<string, unknown> = Record<string, unknown>,
  T extends FieldValues = FieldValues,
> extends FieldBindingBaseProps<T>,
    AddressLookupFieldProps<
      Option,
      Details,
      ChipComponent,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    > {
  name: Path<T>
  addressLookupName: Path<T>
}

function _AddressLookupFieldRHF<
  Option extends AddressLookupOption,
  Details extends Record<string, unknown> = Option,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryResults extends Record<string, unknown> = Record<string, unknown>,
  T extends FieldValues = FieldValues,
  S extends AnyObjectSchema = AnyObjectSchema,
>(
  props: AddressLookupFieldRHFProps<
    Option,
    Details,
    ChipComponent,
    QueryVariables,
    MoreQueryVariables,
    QueryResults,
    MoreQueryResults,
    T
  >,
) {
  const {
    name,
    shouldUnregister = true,
    getOptions: getOptionsProp,
    getOptionLabel: getOptionLabelProp,
    addressLookupName,
    ...other
  } = props

  const { control, formState } = useFormContext<T>()

  const defaultAddress = get(formState.defaultValues, name)

  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController({
    name: addressLookupName,
    control,
    shouldUnregister,
    defaultValue: get(formState.defaultValues, name),
  })

  const { field: addressField } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  const hasDefaultLookupValue = get(formState.defaultValues, addressLookupName) !== undefined
  const hasDefaultAddressValue = defaultAddress !== undefined

  const getOptions = useCallback(
    (data: Option[] | QueryResults | MoreQueryResults) => getOptionsProp?.(data) ?? (data as Option[]),
    [getOptionsProp],
  )

  const handleChange = useCallback<Required<AddressLookupFieldProps<Option, Details, ChipComponent>>['onChange']>(
    (event, value) => {
      if (!value) {
        field.onChange(hasDefaultLookupValue ? null : undefined)
      } else {
        field.onChange((value as Option).value)
      }
    },
    [field, hasDefaultLookupValue],
  )

  const handleAddressChange = useCallback<
    Required<AddressLookupFieldProps<Option, Details, ChipComponent>>['onAddressChange']
  >(
    (value) => {
      if (!value) {
        addressField.onChange(hasDefaultAddressValue ? null : undefined)
      } else {
        addressField.onChange(value)
      }
    },
    [addressField, hasDefaultAddressValue],
  )

  const getOptionLabel = useCallback<AddressLookupFieldProps['getOptionLabel']>(
    (option) => {
      if (typeof option === 'object' && 'label' in option) {
        return parseText?.(option.label) || option.label
      }

      if (typeof option === 'object' && !('label' in option)) {
        const label = getOptionLabelProp?.(option as Option)

        return label ? parseText?.(label) || label : ''
      }

      return option
    },
    [parseText, getOptionLabelProp],
  )

  return (
    <AddressLookupField<
      Option,
      Details,
      ChipComponent,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    >
      {...other}
      onChange={handleChange}
      onAddressChange={handleAddressChange}
      value={field.value as AutocompleteValue<Option, false, false, false>}
      onBlur={field.onBlur}
      name={field.name}
      getOptions={getOptions}
      getOptionLabel={getOptionLabel}
      TextFieldProps={{
        inputRef: field.ref,
        required: !fieldSchema?.spec?.optional,
      }}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
      renderTags={(tagValue, getTagProps) => {
        const tagLimit = props.limitTags || 3

        if (tagValue.length > tagLimit) {
          return <Typography>{`${tagValue.length} Selected`}</Typography>
        } else {
          return tagValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index })

            return (
              <Chip
                key={key}
                label={parseText ? parseText(option.label) : option.label}
                {...tagProps}
                size={props.size === 'large' ? 'medium' : 'small'}
              />
            )
          })
        }
      }}
    />
  )
}

const AddressLookupFieldRHF = React.memo(_AddressLookupFieldRHF) as typeof _AddressLookupFieldRHF

export default AddressLookupFieldRHF
