import { type AnyObject, type AnyObjectSchema, type ObjectSchema, reach } from 'yup'

export default function safeReach<T extends AnyObjectSchema>(schema: T, name: string) {
  let innerSchema: ReturnType<typeof reach<string, ObjectSchema<T, AnyObject, never, ''>>> | undefined = undefined

  try {
    innerSchema = reach(schema, name)
  } catch (e: unknown) {
    if (e instanceof Error) {
      if (e.message.includes('no such schema at')) {
        innerSchema = undefined
      }
    }
  }

  return innerSchema
}
