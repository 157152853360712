import React, { ReactNode } from 'react'
import type { FieldArrayPath, FieldValues } from 'react-hook-form'
import { type FieldArrayRenderFn, FieldBindingBaseProps, useFieldArrayComponent } from '@acre/forms'
import { FieldArray, FieldArrayItem } from '@acre/design-system'

export interface FieldArrayRHFProps<TFieldValues extends FieldValues = FieldValues>
  extends FieldBindingBaseProps<TFieldValues> {
  name: FieldArrayPath<TFieldValues>
  render: FieldArrayRenderFn<TFieldValues>
  hideRemoveButton?: boolean
  addButtonText?: string | ReactNode
}

// TODO: Figure out how to make this configurable

const _FieldArrayRHF = <TFieldValues extends FieldValues>({
  name,
  render: renderProp,
  hideRemoveButton = false,
  addButtonText,
}: FieldArrayRHFProps<TFieldValues>) => {
  const { fields, render, handleAddClick, handleRemoveClick } = useFieldArrayComponent<TFieldValues>({
    name,
    render: renderProp,
    addButtonText: addButtonText,
  })

  return (
    <FieldArray onAddClick={handleAddClick} addButtonText={addButtonText}>
      {fields.map((field, index) => (
        <FieldArrayItem
          key={field.id}
          index={index}
          hideRemoveButton={hideRemoveButton}
          onRemoveClick={handleRemoveClick(index)}
        >
          {render({ field, index })}
        </FieldArrayItem>
      ))}
    </FieldArray>
  )
}

const FieldArrayRHF = React.memo(_FieldArrayRHF) as typeof _FieldArrayRHF

export default FieldArrayRHF
