import { useCallback, useMemo } from 'react'
import { useCaseContext } from '@broker-crm-contexts'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box, Typography, useTheme } from '@mui/material'
import { format, subDays } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { Field, FieldInputProps, useForm, useFormState } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { getFormMetaErrors, testHandle, useFormatMessage } from '@acre/utils'
import { CaseStatus, Client, ClientInput, ClientVersion, LutherAddress, useGetClientsQuery } from '@acre/graphql'
import {
  Banner,
  CenteredLoadingSpinner,
  Checkbox,
  COMPACT_LEFT_ALIGNED,
  DateInput,
  Label,
  LabelAndInputWrapper,
  Tooltip,
  trackEventFiltered,
} from '@acre/design-system'

import { messagePrefix } from '../CompleteTransitionTask.helpers'

const UpdateClientAddressesModalFields = () => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()
  const form = useForm()
  const formStates = useFormState()
  const intl = useIntl()

  const { trackEvent } = useMatomo()
  const location = useLocation()

  const { id, details } = useCaseContext()

  const { data: clientList, loading } = useGetClientsQuery({
    variables: {
      ids: details.client_ids!,
    },
    skip: isEmpty(details.client_ids),
    onCompleted: () => {
      if (hasIncompleteCase) {
        trackEventFiltered(trackEvent, location, 'displayWarningBannerForInflightCases', `display-${id}`)
      }
    },
  })

  const hasIncompleteCase = useMemo(() => {
    return clientList?.clients?.some((client) =>
      client?.details?.cases?.some(
        (c) =>
          c.details.id !== id &&
          c.details.status !== CaseStatus.NotProceeding &&
          c.details.status !== CaseStatus.Complete &&
          c.details.status !== CaseStatus.ImportedComplete,
      ),
    )
  }, [clientList?.clients, id])

  const purchasedPropertyAddress = details.new_property?.details?.address as LutherAddress
  const { address1, postcode, posttown } = purchasedPropertyAddress || {}

  const formattedPurchasedPropertyAddress = `${address1}, ${posttown}, ${postcode}`

  const handleChange = useCallback(
    (client: Client, selectedClients: ClientInput[]) => {
      const isClientSelected = (selectedClients || []).some((c) => c.id === client.id)
      const currentClients = selectedClients || []
      let newClients

      if (isClientSelected) {
        // If already selected, remove this client
        newClients = currentClients.filter((c: ClientInput) => c.id !== client.id)
      } else {
        // If not selected, add this client
        newClients = [...currentClients, client]
      }

      form.change('selectedClients', newClients)
    },
    [form],
  )

  const CheckboxField = (
    client: ClientVersion,
    name: string,
    isClientSelected: boolean,
    input: FieldInputProps<ClientInput[], HTMLElement>,
    hasCurrentAddressBeenUpdated: boolean,
  ) => {
    return (
      <Field name={`selectedClients.${client.id}`} type="checkbox">
        {({ input: checkboxInput }) => (
          <Checkbox
            {...checkboxInput}
            id={`selectedClients.${client.id}`}
            label={name}
            checked={isClientSelected}
            onChange={() => {
              handleChange(client.details, input.value)
            }}
            disabled={hasCurrentAddressBeenUpdated}
          />
        )}
      </Field>
    )
  }

  if (loading) {
    return <CenteredLoadingSpinner />
  }

  return (
    <>
      <Box mb={2}>
        <Typography fontSize={theme.typography.pxToRem(12)}>
          <FormattedMessage id={`${messagePrefix}.updateClientAddressModal.confirmationQuestion`} />
        </Typography>
      </Box>
      <Box mb={2}>
        <LabelAndInputWrapper variant={COMPACT_LEFT_ALIGNED} data-testid={testHandle(id)}>
          <Label
            htmlFor={id}
            isDisabled={false}
            text={formatMessage(`${messagePrefix}.updateClientAddressModal.purchasedProperty`)}
            variant={COMPACT_LEFT_ALIGNED}
          />
          <Box>
            <Typography fontWeight={theme.typography.fontWeightBold}>
              {address1 && `${address1}, `}
              {posttown && `${posttown}, `}
              {postcode && postcode}
            </Typography>
          </Box>
        </LabelAndInputWrapper>
      </Box>
      <Box mb={2}>
        <LabelAndInputWrapper variant={COMPACT_LEFT_ALIGNED} data-testid={testHandle(id)}>
          <Label
            htmlFor={id}
            isDisabled={false}
            text={formatMessage(`${messagePrefix}.updateClientAddressModal.clientOptions`)}
            variant={COMPACT_LEFT_ALIGNED}
          />
          <Field name="selectedClients">
            {({ input }) => (
              <Box display="flex" flexDirection="column" gap={1}>
                {details.clients?.map((client) => {
                  const name = `${client.details.first_name} ${client.details.last_name}`
                  const isClientSelected = (input?.value || []).some((c: ClientVersion) => c.id === client.id)
                  const hasCurrentAddressBeenUpdated = Boolean(
                    client.details.addresses?.some(
                      (address) =>
                        address.is_current_correspondence === true &&
                        address.address.address1 === address1 &&
                        address.address.posttown === posttown &&
                        address.address.postcode === postcode,
                    ),
                  )

                  return (
                    <Box key={client.id}>
                      {hasCurrentAddressBeenUpdated ? (
                        <Tooltip
                          content={
                            <FormattedMessage
                              id={`${messagePrefix}.updateClientAddressModal.tooltip`}
                              values={{
                                name: name,
                                formattedPurchasedPropertyAddress: formattedPurchasedPropertyAddress,
                              }}
                            />
                          }
                          placement="bottom-start"
                          trigger="mouseenter focus"
                        >
                          {CheckboxField(client, name, isClientSelected, input, hasCurrentAddressBeenUpdated)}
                        </Tooltip>
                      ) : (
                        CheckboxField(client, name, isClientSelected, input, hasCurrentAddressBeenUpdated)
                      )}
                    </Box>
                  )
                })}
              </Box>
            )}
          </Field>
        </LabelAndInputWrapper>
      </Box>
      <Box mb={2}>
        <Field name="move_in_date">
          {({ input, meta }) => (
            <DateInput
              {...input}
              {...(!isEmpty(formStates.values?.selectedClients) && getFormMetaErrors({ intl, meta }))}
              id="SetMoveDate"
              label={formatMessage(`${messagePrefix}.updateClientAddressModal.moveDate`)}
              variant={COMPACT_LEFT_ALIGNED}
              disabled={isEmpty(formStates.values?.selectedClients)}
              includeDateShortcutButtons
              customShortcuts={[
                {
                  messageId: 'generic.today',
                  id: 'Today',
                  date: format(new Date(), 'yyyy-MM-dd'),
                },
                {
                  messageId: 'generic.lastWeek',
                  id: 'LastWeek',
                  date: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
                },
              ]}
            />
          )}
        </Field>
      </Box>
      {hasIncompleteCase && (
        <Box my={theme.spacing(2)}>
          <Banner fontSize={14} text={formatMessage(`${messagePrefix}.updateClientAddressModal.warning`)} />
        </Box>
      )}
    </>
  )
}

export default UpdateClientAddressesModalFields
