import React, { ChangeEvent, memo, ReactNode, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { YesNo, yesNoOptions } from '@acre/utils'
import { Maybe } from '@acre/graphql'

import { Variant } from '../../utils/constants'
import Radio from '../Radio'
import RadioGroup from '../RadioGroup'

export type YesNoRadioGroupProps = {
  id: string
  name: string
  label?: ReactNode
  value?: boolean | null
  onChange: (value: boolean) => void
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  reverse?: boolean
  disabled?: boolean
  tooltip?: string | JSX.Element
  error?: boolean
  labelWidth?: Maybe<string>
  lineHeight?: number
}

const YesNoRadioGroup = ({
  id,
  name,
  label,
  value,
  onChange,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant = 'default',
  reverse,
  disabled,
  tooltip,
  error,
  labelWidth,
  lineHeight,
}: YesNoRadioGroupProps) => {
  const { formatMessage } = useIntl()

  // We convert the boolean value into a yes/no string for use in the radio group
  let internalValue: YesNo | undefined = undefined
  if (value === true) internalValue = reverse ? YesNo.No : YesNo.Yes
  if (value === false) internalValue = reverse ? YesNo.Yes : YesNo.No

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value as YesNo
      if (value === YesNo.Yes) onChange(true)
      if (value === YesNo.No) onChange(false)
    },
    [onChange],
  )

  const handleChangeReverseChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value as YesNo
      if (value === YesNo.Yes) onChange(false)
      if (value === YesNo.No) onChange(true)
    },
    [onChange],
  )

  return (
    <RadioGroup
      id={id}
      label={label}
      isMissing={isMissing}
      isIncomplete={isIncomplete}
      verificationError={verificationError}
      variant={variant}
      tooltip={tooltip}
      labelWidth={labelWidth}
      lineHeight={lineHeight}
    >
      {yesNoOptions.map((option) => {
        const label = formatMessage({ id: option.label })
        const checked = internalValue === option.value

        return (
          <Radio
            id={`${id}${option.value}`}
            key={option.value}
            name={name}
            label={label}
            value={option.value}
            checked={checked}
            onChange={reverse ? handleChangeReverseChange : handleChange}
            variant={variant}
            disabled={disabled}
            error={error}
          />
        )
      })}
    </RadioGroup>
  )
}

export default memo(YesNoRadioGroup)
