import { Box, styled } from '@mui/material'
import { transparentize } from 'polished'
import { NavLink } from 'react-router-dom'

export const SideMenuItem = styled(Box, {
  name: 'SideMenu',
  slot: 'item',
})`
  padding: 0;
  margin: 0;

  & + & {
    border-top: 0;
  }

  ul & {
    border: 0;
    width: 100%;
  }
`

export const SideMenuHeading = styled('li')`
  margin: 0;
  border-top: ${({ theme }) => `1px solid ${theme.colours.baseLight}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colours.baseLight}`};
  & + & {
    border-top: 0;
  }
  padding-top: ${({ theme }) => theme.spacers.size8};
  padding-right: ${({ theme }) => theme.spacers.size32};
  padding-bottom: ${({ theme }) => theme.spacers.size8};
  padding-left: ${({ theme }) => theme.spacers.size48};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colours.baseMid};
`

// The styled component underneath is a <Link /> which passes props
// through to the DOM node which doesn't accept booleans (hence the use of 1 | 0 numbers below)
// https://github.com/styled-components/styled-components/issues/1198
export const SideMenuLink = styled(NavLink) <{ disabled?: boolean; heading: 1 | 0 }>`
  padding: ${({ theme }) => `${theme.spacers.size12} ${theme.spacers.size32}`};
  font-size: 14px;
  color: ${({ theme }) => theme.colours.baseMid};
  display: flex;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  text-align: left;
  width: 100%;
  position: relative;

  text-transform: ${({ heading }) => (heading ? 'uppercase' : 'inherit')};
  font-weight: ${({ heading }) => (heading ? 'bold' : 'inherit')};

  ul ul & {
    font-size: 12px;
    padding-top: ${({ theme }) => theme.spacers.size8};
    padding-right: ${({ theme }) => theme.spacers.size32};
    padding-bottom: ${({ theme }) => theme.spacers.size8};
    padding-left: 3.5rem;
  }

  /* For when this link matches our current location */
  &.active {
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:active {
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => transparentize(theme.opacity.op80, theme.colours.primary)};
  }

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colours.baseExtraLight};
    color: ${({ theme }) => theme.colours.baseMid};
  }
`

// ToDo - better solution than just copying SideMenuLink CSS
export const SideMenuNotLink = styled('div') <{ disabled?: boolean }>`
  padding: ${({ theme }) => `${theme.spacers.size12} ${theme.spacers.size32}`};
  font-size: 14px;
  color: ${({ theme }) => theme.colours.baseMid};
  display: flex;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  text-align: left;
  width: 100%;
  position: relative;

  /* For when this link matches our current location */
  &.selected {
    color: ${({ theme }) => theme.colours.primary};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => transparentize(theme.opacity.op90, theme.colours.primary)};
  }

  &:active {
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => transparentize(theme.opacity.op80, theme.colours.primary)};
  }

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colours.baseExtraLight};
    color: ${({ theme }) => theme.colours.baseMid};
  }
`

export const SideMenu = styled('ul')`
  list-style: none outside none;
  margin: 0;
  padding: 0;

  & li > ul > li > button {
    padding-left: ${({ theme }) => `${theme.spacers.size64} !important`};
  }

  cursor: pointer;
`

export const SideMenuArrow = styled('div') <{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  position: absolute;
  right: ${({ theme }) => theme.spacers.size32};
  top: 12px;

  path {
    fill: ${({ theme, open }) => (open ? theme.colours.primary : theme.colours.new.grey4)};
  }
`

export const SideMenuMissingFieldsCount = styled('div')`
  position: absolute;
  right: ${({ theme }) => theme.spacers.size32};
  top: 50%;
  transform: translateY(-50%);
  height: ${({ theme }) => theme.spacers.size16};
  &:nth-last-of-type(2) {
    right: ${({ theme }) => theme.spacers.size64};
  }
`

export const SideMenuNotificationsCount = styled('div')`
  position: absolute;
  right: ${({ theme }) => theme.spacers.size32};
  height: ${({ theme }) => theme.spacers.size24};
  width: ${({ theme }) => theme.spacers.size24};
  color: white;
  background-color: ${({ theme }) => theme.colours.danger};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
