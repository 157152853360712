import React from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { TextField, TextFieldProps } from '@acre/design-system'
// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { type FieldBindingBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'

export interface TextFieldRHFProps<T extends FieldValues = FieldValues>
  extends FieldBindingBaseProps<T>,
    TextFieldProps {
  name: Path<T>
}

function _TextFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: TextFieldRHFProps<T>,
) {
  const { name, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const translatedProps = getCommonTranslatedProps(props, { error: fieldError, parseText })

  const translatedPlaceholder =
    parseText && typeof props.placeholder === 'string' ? parseText(props.placeholder) : props.placeholder

  return (
    <TextField
      {...other}
      {...translatedProps}
      placeholder={translatedPlaceholder}
      onChange={field.onChange}
      value={field.value ?? ''}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
      // Return to this error message when ErrorMessage styling is updated
      // error={<ErrorMessage render={RenderErrorMessage} name={name} />}
      ErrorTextProps={{ slot: 'div' }}
    />
  )
}

const TextFieldRHF = React.memo(_TextFieldRHF) as typeof _TextFieldRHF

export default TextFieldRHF
