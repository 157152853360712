import React, { useMemo } from 'react'
import { Stack, styled, unstable_composeClasses, useMediaQuery, useTheme } from '@mui/material'
import type { FlexboxProps } from '@mui/system'
import classnames from 'classnames'

import type { FieldWrapperProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper from '../FieldWrapper/FieldWrapper'
import { getFieldGroupUtilityClass } from './fieldGroupClasses'

export interface FieldGroupProps extends FieldWrapperProps {
  name: string
  groupLayout?: 'row' | 'column'
  groupAlign?: FlexboxProps['alignItems']
}

const FieldGroupRoot = styled(FieldWrapper, {
  name: 'FieldGroup',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

export interface FieldGroupFieldsProps extends Pick<FieldGroupProps, 'groupLayout'> {}

const FieldGroupFields = styled(Stack, {
  name: 'FieldGroup',
  slot: 'Fields',
  overridesResolver: (props, styles) => styles.root,
})<FieldGroupFieldsProps>(({ theme }) => ({
  gap: theme.spacing(1),
  flexWrap: 'wrap',

  '& > *': {
    flex: 1,
    minWidth: '100px',
  },
}))

const useUtilityClasses = (ownerState: Partial<FieldGroupProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    label: ['label'],
    fields: ['fields', ownerState.layout || 'column'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getFieldGroupUtilityClass, ownerState.classes)
}

const _FieldGroup = (props: FieldGroupProps) => {
  const {
    className,
    classes,
    children,
    responsive,
    groupLayout = 'column',
    groupAlign = props.groupLayout === 'row' ? 'flex-start' : 'stretch',
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, layout: rootProps.layout, size: rootProps.size, groupLayout })

  const theme = useTheme()
  const shouldBreak = useMediaQuery(theme.breakpoints.down('sm')) && responsive

  const { labelId, fieldAriaProps } = useAriaProps(props)

  const fields = useMemo(() => {
    let childIndex = 0

    return React.Children.map(children as React.ReactElement[], (child) => {
      const result =
        child.type === React.Fragment ? (
          <>
            {(child.props.children as React.ReactElement[]).map((innerChild, innerIndex) =>
              React.cloneElement(innerChild, { key: innerIndex, size: rootProps.size }),
            )}
          </>
        ) : (
          React.cloneElement(child, { key: childIndex, size: rootProps.size })
        )

      childIndex++

      return result
    })
  }, [children, rootProps.size])

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  return (
    <FieldGroupRoot
      {...rootProps}
      {...fieldAriaProps}
      aria-labelledby={labelId}
      role="group"
      className={classnames(slotClasses.root, className)}
      responsive={responsive}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
    >
      <FieldGroupFields
        className={slotClasses.fields}
        direction={shouldBreak ? 'column' : groupLayout}
        alignItems={groupAlign}
      >
        {fields}
      </FieldGroupFields>
    </FieldGroupRoot>
  )
}

const FieldGroup = React.memo(_FieldGroup) as typeof _FieldGroup

export default FieldGroup
