import React, { useCallback } from 'react'
import type { AutocompleteChangeDetails, ChipTypeMap } from '@mui/material'
import { unstable_composeClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import classnames from 'classnames'

import AutocompleteField, { type AutocompleteFieldProps } from '../AutocompleteField/AutocompleteField'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import type { AddressLookupOption } from './AddressLookupField.types'
import { getAddressLookupFieldUtilityClass } from './addressLookupFieldClasses'

export interface AddressLookupFieldProps<
  Option extends AddressLookupOption = AddressLookupOption,
  Details extends Record<string, any> = Option,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> | string = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
> extends Omit<
    AutocompleteFieldProps<
      Option,
      false,
      false,
      false,
      ChipComponent,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    >,
    'asyncMode'
  > {
  loadAddressDetails?: (args?: QueryVariables) => Promise<Details | undefined>
  onAddressChange?: (
    value: Option | Details | undefined | null,
    originalValue: Option | undefined | null,
    details?: AutocompleteChangeDetails<Option>,
  ) => void

  // We make this field required so we can automatically apply isOptionEqualToValue, which
  // simplifies the setup of search fields
  getOptionLabel: <T extends AddressLookupOption = Option>(option: T) => string
}

const AddressLookupFieldRoot = styled(AutocompleteField, {
  name: 'AddressLookupField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(() => ({})) as typeof AutocompleteField

const useUtilityClasses = <Option extends AddressLookupOption>(
  ownerState: Partial<AddressLookupFieldProps<Option>>,
) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    select: ['select'],
    helperText: ['helperText'],
    errorText: ['errorText'],
    list: ['list'],
    option: ['option'],
    placeholder: ['placeholder'],
    searchIcon: ['searchIcon'],
    endAdornment: ['endAdornment'],
    clearSearchButton: ['clearSearchButton'],
  }

  return unstable_composeClasses(slots, getAddressLookupFieldUtilityClass, ownerState.classes)
}

function _AddressLookupField<
  Option extends AddressLookupOption,
  Details extends Record<string, any> = Option,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends unknown = Record<string, unknown>,
  MoreQueryResults extends unknown = QueryResults,
>(
  props: AddressLookupFieldProps<
    Option,
    Details,
    ChipComponent,
    QueryVariables,
    MoreQueryVariables,
    QueryResults,
    MoreQueryResults
  >,
) {
  const {
    className,
    InputLabelProps,
    FormHelperTextProps,
    ErrorTextProps,
    layout = 'row',
    size = 'medium',
    loadAddressDetails,
    onChange,
    onAddressChange,
    isOptionEqualToValue: isOptionEqualToValueProp,
    TextFieldProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses<Option>({ layout, size, disabled: props.disabled })

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, {
    ...props,
    FormHelperTextProps,
    ErrorTextProps,
    InputLabelProps,
  })

  const [loading, setLoading] = React.useState<true | undefined>(undefined)

  const handleChange = useCallback<
    Required<
      AddressLookupFieldProps<
        Option,
        Details,
        ChipComponent,
        QueryVariables,
        MoreQueryVariables,
        QueryResults,
        MoreQueryResults
      >
    >['onChange']
  >(
    async (e, option, reason, details) => {
      if (reason === 'selectOption') {
        if (loadAddressDetails) {
          setLoading(true)

          const result = await loadAddressDetails(option as QueryVariables)

          if (result) {
            onChange?.(e, option, reason, details)
            onAddressChange?.(result, option, details)
          }

          setLoading(undefined)
        } else {
          onChange?.(e, option, reason, details)
          onAddressChange?.(option, option, details)
        }
      } else if (reason === 'clear') {
        onChange?.(e, option, reason, details)
        onAddressChange?.(null, null, details)
      }
    },
    [loadAddressDetails, onAddressChange, onChange],
  )

  const isOptionEqualToValue = useCallback<Required<AddressLookupFieldProps<Option>>['isOptionEqualToValue']>(
    (option, value) => {
      if (isOptionEqualToValueProp) {
        return isOptionEqualToValueProp(option, value)
      }

      return props.getOptionLabel?.(option) === props.getOptionLabel?.(value)
    },
    [isOptionEqualToValueProp, props],
  )

  return (
    <AddressLookupFieldRoot
      autoComplete
      asyncMode="search"
      {...rootProps}
      isOptionEqualToValue={isOptionEqualToValue}
      className={classnames(slotClasses.root, className)}
      onChange={handleChange}
      loading={loading}
      TextFieldProps={{
        ...TextFieldProps,
        className: slotClasses.field,
        inputProps: { className: slotClasses.select },
        InputLabelProps: { ...TextFieldProps?.InputLabelProps, ...labelProps },
        FormHelperTextProps: { ...TextFieldProps?.FormHelperTextProps, ...helperTextProps },
        ErrorTextProps: { ...TextFieldProps?.ErrorTextProps, ...errorProps },
      }}
      ListboxProps={{ className: slotClasses.list }}
      InputLabelProps={labelProps}
      optionClassName={slotClasses.option}
    />
  )
}

const AddressLookupField = React.memo(_AddressLookupField) as typeof _AddressLookupField

export default AddressLookupField
