import {
  cancellationReason,
  caseWaterfall,
  caseWaterfallIntroducer,
  consumerDutyResults,
  currentPipeline,
  predictedRevenue,
  reportsDiscovery,
  reportsFilterOptions,
  revenue,
  tier,
} from '../api'
import {
  QueryConsumerDutyResultsArgs,
  QueryReportsCancellationReasonArgs,
  QueryReportsCaseWaterfallArgs,
  QueryReportsCaseWaterfallIntroducerArgs,
  QueryReportsCommissionsArgs,
  QueryReportsCurrentPipelineArgs,
  QueryReportsPredictedRevenueArgs,
  QueryReportsRevenueArgs,
  Resolvers,
} from '../generated/resolvers'

const ReportsResolver: Resolvers = {
  Query: {
    reportsCurrentPipeline: (_, input: QueryReportsCurrentPipelineArgs) => currentPipeline(input),
    reportsCancellationReason: (_, input: QueryReportsCancellationReasonArgs) => cancellationReason(input),
    reportsCaseWaterfall: (_, input: QueryReportsCaseWaterfallArgs) => caseWaterfall(input),
    reportsCaseWaterfallIntroducer: (_, input: QueryReportsCaseWaterfallIntroducerArgs) =>
      caseWaterfallIntroducer(input),
    reportsRevenue: (_, input: QueryReportsRevenueArgs) => revenue(input),
    reportsCommissions: (_, input: QueryReportsCommissionsArgs) => tier(input),
    reportsPredictedRevenue: (_, input: QueryReportsPredictedRevenueArgs) => predictedRevenue(input),
    reportsDiscovery: () => reportsDiscovery(),
    reportsFilterOptions: (_, input) => reportsFilterOptions(input),
    consumerDutyResults: (_, input: QueryConsumerDutyResultsArgs) => consumerDutyResults(input),

  },
}

export default ReportsResolver
