import React, { ReactNode } from 'react'
import { Box, type BoxProps, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import Button from '../ButtonNewest/Button'
import { getFieldArrayUtilityClass } from './fieldArrayClasses'

export interface FieldArrayProps extends BoxProps {
  onAddClick: () => void
  hideAddButton?: boolean
  addButtonText?: string | ReactNode
}

const FieldArrayRoot = styled(Box, {
  name: 'FieldArray',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const useUtilityClasses = (ownerState: Partial<FieldArrayProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getFieldArrayUtilityClass, ownerState.classes)
}

const DEFAULT_ADD_BUTTON_TEXT = '+ Add Item'

const _FieldArray = (props: FieldArrayProps) => {
  const {
    className,
    classes,
    children,
    onAddClick,
    hideAddButton,
    addButtonText = DEFAULT_ADD_BUTTON_TEXT,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes })

  return (
    <FieldArrayRoot {...rootProps} className={classnames(slotClasses.root, className)}>
      {children}

      {!hideAddButton && (
        <Button fullWidth variant="secondary" size="medium" onClick={onAddClick}>
          {addButtonText}
        </Button>
      )}
    </FieldArrayRoot>
  )
}

const FieldArray = React.memo(_FieldArray) as typeof _FieldArray

export default FieldArray
