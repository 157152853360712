import { ChangeEvent } from 'react'
import { fieldParserFn } from '@broker-crm-common'
import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { renderWithLineBreaks, useFormatMessage } from '@acre/utils'
import { ButtonNewest, TextAreaNew } from '@acre/design-system'

import testHandle from '../../../../../../../common/design-system/src/utils/testHandle'
import { messagePrefix } from '../PageNotes.helpers'
import { TextContainer, TextUnderlay } from './AddNewPageNotes.styles'

type Props = {
  noteBody: string
  addingNote: boolean
  handleNoteChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  handleAddNote: () => void
}

const AddNewPageNotesContent = ({ handleNoteChange, handleAddNote, addingNote, noteBody }: Props) => {
  const formatMessage = useFormatMessage()

  return (
    <>
      <Field name="pageNotes" parse={fieldParserFn}>
        {({ input }) => (
          <TextContainer>
            <TextUnderlay>{renderWithLineBreaks(noteBody)}</TextUnderlay>
            <TextAreaNew
              id="AddNewPageNotes"
              {...input}
              value={noteBody}
              onChange={handleNoteChange}
              placeholder={formatMessage(`${messagePrefix}.typeANote`)}
              name="AddNewPageNotes"
              ariaLabel="AddNewPageNotes"
            />
          </TextContainer>
        )}
      </Field>
      <Box py={1}>
        <ButtonNewest
          id="AddNewPageNotesButton"
          data-testid={testHandle('AddNewPageNotesButton')}
          variant="primary"
          size="large"
          fullWidth
          onClick={handleAddNote}
          loading={addingNote}
          disabled={isEmpty(noteBody)}
        >
          <FormattedMessage id={`${messagePrefix}.saveNoteToPage`} />
        </ButtonNewest>
      </Box>
    </>
  )
}

export default AddNewPageNotesContent
