import { useMemo } from 'react'
import { get, type DefaultValues, type FieldValues, set } from 'react-hook-form'

import type {
  FormSchema
} from '../types/schema'
import type {
  FormCustomContext,
  InferPropsMap,
  ComponentMapBase,
  ComponentSchemaPropsMapBase,
} from '../types/schema-base'
import indexBlocks from '../util/indexBlocks'

export default function useParseSchema<
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TComponentPropMaps extends Partial<ComponentSchemaPropsMapBase> = InferPropsMap<TComponentMap>,
>(
  schema: Omit<FormSchema<TFieldValues, TComponentMap, TContext>, 'validationSchema'>,
  defaultValues?: DefaultValues<TFieldValues>,
) {
  return useMemo(() => {
    const { blocks, transforms, calculatedFields, ...formProps } = schema

    const blockIndex = indexBlocks<TFieldValues, TComponentPropMaps, TContext>(blocks)

    if (!defaultValues && !formProps.defaultValues) {
      return { error: new Error('defaultValues must be provided via props or schema') }
    }

    const _defaultValues = { ...defaultValues, ...formProps.defaultValues } as Record<
      keyof DefaultValues<TFieldValues>,
      unknown
    >

    if (transforms && Object.keys(_defaultValues).length) {
      for (const key in blockIndex) {
        const value = get(defaultValues, key)

        const fieldType = blockIndex[key].type

        if (fieldType in transforms && transforms[fieldType]) {
          set(_defaultValues, key, transforms[fieldType]?.(value, 'in'))
        }
      }
    }

    const ephemerals = Object.entries(blockIndex).reduce<string[]>((acc, [name, block]) => {
      if ('shouldSubmit' in block && block.shouldSubmit === false) {
        acc.push(name)
      }
      return acc
    }, [])

    if (calculatedFields) {
      for (const [key, block] of Object.entries(calculatedFields)) {
        if (block && ('shouldSubmit' in block && block.shouldSubmit === false)) {
          ephemerals.push(key)
        }
      }
    }

    return {
      blocks,
      blockIndex,
      formProps: {
        ...formProps,
        defaultValues: _defaultValues as DefaultValues<TFieldValues>,
        ephemerals,
      },
    }
  }, [defaultValues, schema])
}
