import { memo, useEffect } from 'react'
import { FieldValues, Path, useController, useFormContext, useWatch } from 'react-hook-form'

import useFormSchema from '../../hooks/useFormSchema'
import { FormCustomContext } from '../../types/schema-base'

export interface CalculatedFieldProps<TFieldValues extends FieldValues = FieldValues> {
  name: Path<TFieldValues>
}

const _CalculatedField = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends FormCustomContext = FormCustomContext,
>({
  name,
}: CalculatedFieldProps<TFieldValues>) => {
  const { schema, context } = useFormSchema<TContext, TFieldValues>()
  const calculatedFieldSchema = schema?.calculatedFields?.[name]

  const { control, getValues } = useFormContext<TFieldValues>()

  const controller = useController<TFieldValues>({
    control,
    name,
  })

  useWatch<TFieldValues>({ name: (calculatedFieldSchema?.deps ?? []) as Path<TFieldValues>[], control })

  const value = calculatedFieldSchema?.fn(getValues(), context)

  useEffect(() => {
    controller.field.onChange(value)
  }, [calculatedFieldSchema, context, controller.field, getValues, value])

  return <input type="hidden" {...controller.field} value={value ?? ''} />
}

const CalculatedField = memo(_CalculatedField)

export default CalculatedField
