import { GraphQLError } from 'graphql'

import {
  createOrganisation,
  fetchOrganisation,
  fetchOrganisationByExtId,
  fetchOrganisationsAdministeredBy,
  fetchOrganisationsBySourceType,
  fetchOrganisationsMiRepresentativeOf,
  fetchOrganisationsRepresentativeOf,
  fetchTemplatesForOrg,
  fetchUsersForOrg,
  OrganisationLoader,
  OrgSourceType,
  updateOrganisation,
} from '../api'
import { setupOrganisation } from '../api/acctmgmt'
import { getCommissionStructure } from '../api/commission_structure'
import { getMiOrganisations } from '../api/firms'
import { fetchOrganisationByExtIdCP, fetchOrganisationsAdministeredByCP } from '../api_client_portal/organisations'
import { Organisation, Resolvers } from '../generated/resolvers'

const OrganisationResolver: Resolvers = {
  Query: {
    miOrganisations: async (_parent, { filters }) => {
      const bookmarkValue = filters?.bookmark === null ? undefined : filters?.bookmark;
      const pageSizeValue = filters?.page_size === null ? undefined : filters?.page_size;

      return await fetchOrganisationsMiRepresentativeOf({ bookmark: bookmarkValue, page_size: pageSizeValue, ...filters })

    },
    organisation: async (_parent, { id, useClientApi }) => {
      const result = await OrganisationLoader.load({ id, useClientApi: useClientApi || false })

      if (result instanceof GraphQLError) {
        throw result
      }

      return result
    },
    organisations: async (_parent, { ids, useClientApi }) => {
      if (!ids) {
        throw new GraphQLError('ids is required', {
          extensions: {
            status: 400,
          },
        })
      }

      const result = await OrganisationLoader.loadMany(ids.map((id) => ({ id, useClientApi: useClientApi || false })))
      const organisations: Organisation[] = []

      result.forEach((result) => {
        if (result instanceof GraphQLError) {
          console.error(result)
        } else {
          organisations.push(result)
        }
      })

      return organisations
    },
    organisationsRepresentativeOf: async (_parent, { filter_representative_of_org_id }) => {
      const response = await fetchOrganisationsRepresentativeOf(filter_representative_of_org_id)
      return response.organisations || []
    },
    lenderNames: async () => {
      const lenderOrg = await fetchOrganisationByExtId('myac.re/lender_management')
      const lenderOrgId = lenderOrg && lenderOrg.id

      if (lenderOrgId) {
        return await fetchOrganisationsBySourceType(OrgSourceType.Lender)
      }

      return []
    },
    lenderNamesCp: async () => {
      const lenderOrg = await fetchOrganisationByExtIdCP('myac.re/lender_management')
      const lenderOrgId = lenderOrg?.id

      if (lenderOrgId) {
        return await fetchOrganisationsAdministeredByCP(lenderOrgId)
      }

      return []
    },
    mortgageClubs: async () => {
      const lenderOrg = await fetchOrganisationByExtId('myac.re/mortgage_club_management')
      const lenderOrgId = lenderOrg && lenderOrg.id

      if (lenderOrgId) {
        return await fetchOrganisationsAdministeredBy(lenderOrgId)
      }

      return []
    },
    providerNames: async () => {
      const insurerOrg = await fetchOrganisationByExtId('myac.re/insurer_management')
      const insurerOrgId = insurerOrg && insurerOrg.id

      if (insurerOrgId) {
        return await fetchOrganisationsAdministeredBy(insurerOrgId)
      }

      return []
    },
    getFirmList: async (_parent, { filter }) => {
      return await getMiOrganisations(filter)
    },
  },
  Mutation: {
    createOrganisation: (_parent, { input }) => createOrganisation(input),
    updateOrganisation: (_parent, { id, input }) => updateOrganisation(id, input),
    addOrganisationFees: (_parent, { id, input }) => updateOrganisation(id, { standard_fee_options: input }),
    setupOrganisation: (_parent, { input }) => setupOrganisation(input),
  },
  Organisation: {
    commission_structure: async ({ organisation_id }, { introducerId }) => {
      const res = await getCommissionStructure({
        input: {
          filter_paying_organisation_id: organisation_id,
          filter_receiving_organisation_id: introducerId,
        },
      })

      // The BE currently allows a single commissions structure between two orgs
      // hence why we just pick the first one from the list here, which represents
      // the commissions structure between the org and the introducer
      // where the org is the paying org and introducer is the recieving org
      return res.commission_structures?.[0] || null
    },
    paid_by: async ({ paid_by }) => {
      if (!paid_by) {
        return null
      }

      const organisation = await fetchOrganisation(paid_by.id)

      if (organisation instanceof GraphQLError) {
        return null
      }

      return organisation
    },
    representative_of_org: async (parent) => {
      const { representative_of } = parent

      if (!representative_of) {
        return null
      }

      const organisation = await fetchOrganisation(representative_of)

      if (organisation instanceof GraphQLError) {
        throw organisation
      }

      return organisation
    },
    arOrganisationsMi: async ({ organisation_id }, { bookmark, page_size }) => {

      const bookmarkValue = bookmark === null ? undefined : bookmark;
      const pageSizeValue = page_size === null ? undefined : page_size;

      return organisation_id ? await fetchOrganisationsMiRepresentativeOf({ representative_of: organisation_id, include_disabled_orgs: true, all_users: true, bookmark: bookmarkValue, page_size: pageSizeValue }) : null
    },
    templates: async ({ organisation_id }) => fetchTemplatesForOrg(organisation_id),
    users: async ({ organisation_id }) => await fetchUsersForOrg(organisation_id),
  },
  User: {
    organisation: async ({ organisation_id }, { useClientApi }) => {
      if (!organisation_id) {
        throw new GraphQLError('User does not belong to an organisation', {
          extensions: {
            status: 400,
          },
        })
      }

      const organisation = await fetchOrganisation(organisation_id, useClientApi)

      if (organisation instanceof GraphQLError) {
        throw organisation
      }

      return organisation
    },
  },
  CommissionStructure: {
    paying_organisation: async ({ paying_organisation_id }) => {
      if (!paying_organisation_id) {
        return null
      }

      const organisation = await fetchOrganisation(paying_organisation_id)

      if (organisation instanceof GraphQLError) {
        throw organisation
      }

      return organisation
    },
    receiving_organisation: async ({ receiving_organisation_id }) => {
      if (!receiving_organisation_id) {
        return null
      }

      const organisation = await fetchOrganisation(receiving_organisation_id)

      if (organisation instanceof GraphQLError) {
        throw organisation
      }

      return organisation
    },
  },
}

export default OrganisationResolver
