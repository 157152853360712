import DOMPurify from 'dompurify'

type SanitizeOptions = {
  allowedTags?: string[]
  allowedAttributes?: string[]
  allowLinks?: boolean // if true, includes a tags and href attributes
  stripHTML?: boolean
  /**
   * When set to true, DOMPurify wraps the sanitized HTML output inside a <body> element 
   * It's useful when working with HTML tags such as <title> or <style>
   * othwerwise these tags will be automatically stripped out
   */
  forceBody?: boolean
}

export const sanitizeHtml = (
  html: string,
  options: SanitizeOptions = {
    allowedTags: [],
    allowedAttributes: [],
    allowLinks: true,
    stripHTML: false,
    forceBody: false,
  }
) => {
  const config: DOMPurify.Config = {
    FORCE_BODY: options.forceBody,
  }

  // Strip all HTML tags and attributes
  if (options.stripHTML) {
    config.ADD_TAGS = []
    config.ADD_ATTR = []
    config.ALLOWED_TAGS = []
    config.ALLOWED_ATTR = []

    return DOMPurify.sanitize(html, config)
  }

  if (options.allowLinks) {
    config.ADD_TAGS = ['a']
    config.ADD_ATTR = ['href']
    // Sanitize urls by only allowing: 
    // - URLs with protocols
    // - sequences of lowercase letters, plus signs, dots, or hyphens followed by a character that is not a lowercase letter, plus sign, dot, hyphen, or colon, or the end of the string
    config.ALLOWED_URI_REGEXP = /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|skype|whatsapp|zoom|teams|maps):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i
  }

  // Override default allowed tags https://github.com/cure53/DOMPurify/blob/main/src/tags.ts
  if (options.allowedTags?.length) {
    config.ALLOWED_TAGS = options.allowedTags
  }
  // Override default allowed attributes https://github.com/cure53/DOMPurify/blob/main/src/attrs.ts
  if (options.allowedAttributes?.length) {
    config.ALLOWED_ATTR = options.allowedAttributes
  }

  return DOMPurify.sanitize(html, config)
}
