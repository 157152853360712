import React from 'react'
// import { FormHelperText } from '@mui/material'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import { CheckboxField, CheckboxFieldProps } from '@acre/design-system'
import { type FieldBindingBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'
import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'

// import ErrorMessageDisplay, { RenderErrorMessageProps } from '../../common/ErrorMessageDisplay'
// import { ErrorMessage } from '@acre/forms'

export interface CheckboxFieldRHFProps<T extends FieldValues = FieldValues>
  extends Omit<CheckboxFieldProps, 'value' | 'onChange' | 'onBlur' | 'inputRef' | 'name'>,
    FieldBindingBaseProps<T> {
  name: Path<T>
}

function _CheckboxFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: CheckboxFieldRHFProps<T>,
) {
  const { name, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const translatedProps = getCommonTranslatedProps(props, { error: fieldError, parseText })

  // const renderError = useCallback(
  //   ({ message, messages }: RenderErrorMessageProps) =>
  //     messages ? (
  //       <FormHelperText id={errorId} component="ul" sx={{ ml: '14px' }} error>
  //         <ErrorMessageDisplay message={message} messages={messages} />
  //       </FormHelperText>
  //     ) : null,
  //   [errorId],
  // )

  return (
    <CheckboxField
      {...other}
      {...translatedProps}
      onChange={field.onChange}
      checked={field.value ?? false}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
    />
  )
}

const CheckboxFieldRHF = React.memo(_CheckboxFieldRHF) as typeof _CheckboxFieldRHF

export default CheckboxFieldRHF
