import { useEffect, useState } from 'react'

import { useUserContext } from '@acre/utils'
import { Maybe } from '@acre/graphql'
import config from '@acre/config'

import ServiceMessageAlert from './ServiceMessageAlert'
import { fetchEnvironment, ServiceMessage, ServiceMessageSection } from './ServiceMessageBanner.helpers'

const ServiceMessageBanner = ({ sections }: { sections: ServiceMessageSection[] }) => {
  const { CONTENTFUL_SPACES_ID, CONTENTFUL_API_KEY } = config
  const user = useUserContext()
  const environment = fetchEnvironment()

  const url = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACES_ID}/environments/${environment}/entries?access_token=${CONTENTFUL_API_KEY}&content_type=serviceNotice`

  const [serviceMessages, setServiceMessages] = useState<Maybe<ServiceMessage[]>>(null)

  useEffect(() => {
    async function fetchServiceMessages() {
      const res = await fetch(url)
      const json = await res.json()
      if (json?.items?.length) {
        const filteredMessages = json.items.filter(({ fields }: { fields: Maybe<ServiceMessage> }) => fields)
        setServiceMessages(filteredMessages.map((message: { fields: ServiceMessage }) => message.fields))
      }
    }
    fetchServiceMessages()
  }, [url])

  const reviewModeEnabled = location.pathname.includes('review-mode')

  return serviceMessages?.map((serviceMessage, index) => {
    const { includeAllOrgs, excludedOrgs, includedOrgs, section: messageSection } = serviceMessage

    const matchesSelectedSections = sections?.some((section) => messageSection?.includes(section))

    const isExcludedOrg =
      excludedOrgs?.includes(user?.organisation_id || '') ||
      excludedOrgs?.includes(user?.organisation?.representative_of || '')

    const isIncludedOrg =
      includedOrgs?.includes(user?.organisation_id || '') ||
      includedOrgs?.includes(user?.organisation?.representative_of || '')

    // If includeAllOrgs is set to true, exclude org field is considered, otherwise include org field is considered
    const matchesTargetOrg = includeAllOrgs ? !isExcludedOrg : isIncludedOrg

    const displayAlert = user?.organisation_id
      ? matchesSelectedSections && matchesTargetOrg && !reviewModeEnabled
      : matchesSelectedSections && !reviewModeEnabled

    if (displayAlert) {
      return <ServiceMessageAlert key={`ServiceMessage${index}`} serviceMessage={serviceMessage} />
    }
  })
}

export default ServiceMessageBanner
