import { memo } from 'react'
import type { FallbackProps } from 'react-error-boundary'

const _DebugErrorBoundaryFallback = ({ error }: Omit<FallbackProps, 'resetErrorBoundary'>) => {
  const _error = error as Error

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red', whiteSpace: 'normal' }}>{_error.message}</pre>
    </div>
  )
}

const DebugErrorBoundaryFallback = memo(_DebugErrorBoundaryFallback)

export default DebugErrorBoundaryFallback
