import React from 'react'
import { useCallback } from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import {
  DateField,
  DateFieldProps,
  HIDE_DAY_VALUE_PARSE,
  OBFUSCATED_VALUE_FORMAT,
  OBFUSCATED_VALUE_PARSE,
  VALUE_FORMAT,
  VALUE_PARSE,
} from '@acre/design-system'
// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { type FieldBindingBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'

export interface DateFieldRHFProps<T extends FieldValues = FieldValues>
  extends FieldBindingBaseProps<T>,
    DateFieldProps {
  name: Path<T>
}

function _DateFieldRHF<T extends FieldValues = FieldValues, S extends AnyObjectSchema = AnyObjectSchema>(
  props: DateFieldRHFProps<T>,
) {
  const {
    name,
    shouldUnregister = true,
    parseValue = VALUE_PARSE,
    formatValue = VALUE_FORMAT,
    parseObfuscatedValue = OBFUSCATED_VALUE_PARSE,
    formatObfuscatedValue = OBFUSCATED_VALUE_FORMAT,
    hideDayParseValue = HIDE_DAY_VALUE_PARSE,
    ...other
  } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  const handleChange = useCallback<Required<DateFieldProps>['onChange']>(
    (value) => {
      field.onChange({ target: { value } })
    },
    [field],
  )

  return (
    <DateField
      {...other}
      onChange={handleChange}
      value={field.value}
      onBlur={field.onBlur}
      name={field.name}
      InputProps={{ inputRef: field.ref }}
      required={!fieldSchema?.spec?.optional}
      // Return to this error message when ErrorMessage styling is updated
      // error={<ErrorMessage render={RenderErrorMessage} name={name} />}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
      parseValue={parseValue}
      formatValue={formatValue}
      parseObfuscatedValue={parseObfuscatedValue}
      formatObfuscatedValue={formatObfuscatedValue}
      hideDayParseValue={hideDayParseValue}
    />
  )
}

const DateFieldRHF = React.memo(_DateFieldRHF) as typeof _DateFieldRHF

export default DateFieldRHF
