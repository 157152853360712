import html2canvas from 'html2canvas'

import { GetCaseQuery, User } from '@acre/graphql'

export type ProblemReportForm = {
  title?: string
  situation?: string
  expectation?: string
  problem?: string
  screenshot?: boolean
}

export const SERVICE_REQUEST_TYPE = 'Service Request'

export const captureAndReturnScreenshot = async () => {
  // Find and hide the modal temporarily
  const modalElement = document.getElementById('ReportProblemModal')
  if (modalElement) {
    modalElement.style.display = 'none'
  }

  // Capture the screenshot
  const canvas = await html2canvas(document.body)

  // Convert the Canvas to a Data URL (base64)
  return canvas.toDataURL('image/png').split(',')[1]
}

const buildUserInfo = (user?: User | null) => `
- Full Name: ${user?.first_name || ''} ${user?.last_name || ''}
- User ID: ${user?.id || 'N/A'}
`

const buildOrgInfo = (user?: User | null) => `
- Name: ${user?.organisation?.name || 'N/A'}
- Organisation ID: ${user?.organisation?.id || 'N/A'}
- Organisation Type: ${user?.organisation?.type || 'N/A'}
- Representative Of: ${user?.organisation?.representative_of || 'N/A'}
`

const buildCaseInfo = (caseDetails?: GetCaseQuery['case']) => {
  if (!caseDetails) return ''
  const clientDetails =
    caseDetails?.details?.clients
      ?.map(
        (client) =>
          `- Client ID: ${client?.id}, Is a non-natural client: ${client?.details?.non_natural ? 'Yes' : 'No'}`,
      )
      .join('\n') || 'N/A'

  return `
- ID: ${caseDetails?.id || 'N/A'}
- Case Owner ID: ${caseDetails?.details?.owner_user_id || 'N/A'}
- Case Owner Name: ${caseDetails?.details?.owner?.first_name || ''}  ${caseDetails?.details?.owner?.last_name || ''}

Case Clients:
${clientDetails}
`
}

export const buildReportTemplate = (
  currentLocation: string,
  inputValues: ProblemReportForm,
  user?: User | null,
  caseDetails?: GetCaseQuery['case'],
) => `
User Information:
${buildUserInfo(user)}

Organisation Information:
${buildOrgInfo(user)}

Page/Module Details:
- Current Location/Page: ${currentLocation || 'N/A'}

Problem Description:
- Intended Action: ${inputValues.situation || 'N/A'}
- Expected Outcome: ${inputValues.expectation || 'N/A'}
- Actual Outcome: ${inputValues.problem || 'N/A'}

${caseDetails ? `Case Information: ${buildCaseInfo(caseDetails)}` : ''}

Attachments: 
${inputValues.screenshot
    ? 'Available: A screenshot or attachment has been provided.'
    : 'Unavailable: No screenshot or attachment provided.'
  }
`

export const extractCaseUUID = (path?: string) => {
  return path?.startsWith('/cases/') ? path.split('/')[2] || null : null
}
