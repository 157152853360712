import config from '@acre/config'

import {
  createClientOrganisationRelationship,
  createOrganisationTemplate,
  getMiDocumentSummaries,
  postClientInviteTemplate,
  renderTemplate,
} from '../api'
import { ClientOrganisationRelationshipType, MiDocumentSummary, Resolvers, TemplateType } from '../generated/resolvers'
import { CdmRenderTemplateRequest } from '../service/luther/model'

const TemplateResolver: Resolvers = {
  Mutation: {
    postClientInvites: async (
      _,
      { emailInput, templateInput, organisationPrivacyPayloadInput, creditReportPayloadInput },
    ) => {
      // filter out client ids that already have client portal idd generated
      let clientsIdsThatNeedIdds: string[] = []
      let clientsIdsThatNeedOrgPrivacyPolicy: string[] = []

      const findCreditReportIDD = (documents?: MiDocumentSummary[]) => {
        if (!documents) return null

        return documents.find(document =>
          document?.rendered_organisation_id === config.ACRE_SUPPORT_ORG_ID
        ) || null
      }

      if (templateInput?.client_ids) {
        for (const clientId of templateInput.client_ids) {
          const clientDocuments = await getMiDocumentSummaries('client', clientId, { include_archived: false })

          // Check for existing CP IDD
          const regularCpIdd = clientDocuments?.documents?.find(
            document =>
              document.template_type === TemplateType.ClientPortalIdd &&
              document.rendered_organisation_id != config.ACRE_SUPPORT_ORG_ID
          ) || null

          const hasExistingCpIdd = regularCpIdd !== null

          if (!hasExistingCpIdd) {
            clientsIdsThatNeedIdds.push(clientId)
          }

          // Check for existing privacy policies
          const existingOrgPrivacyPolicies = clientDocuments?.documents?.some(
            (document) => document.template_type === TemplateType.OrganisationPrivacyNotice,
          )
          if (!existingOrgPrivacyPolicies) {
            clientsIdsThatNeedOrgPrivacyPolicy.push(clientId)
          }
        }
      }

      // Step 1: Create CP IDDs where needed
      // First, create CP IDDs for all clients who need them
      const hasIddsForAllClients = clientsIdsThatNeedIdds.length === 0
      // as first step render needed client portal idd template
      const clientPortalIdd = !hasIddsForAllClients && (await renderTemplate({
        ...templateInput,
        client_ids: clientsIdsThatNeedIdds
      } as CdmRenderTemplateRequest))

      // Step 2: Create Credit IDDs if needed
      if (creditReportPayloadInput && templateInput?.client_ids) {
        for (const clientId of templateInput.client_ids) {
          const clientDocuments = await getMiDocumentSummaries('client', clientId, { include_archived: false })
          let creditReportIDD = null

          // Check existing CP IDDs first
          const existingCpIdds = clientDocuments?.documents?.filter(
            (document) => document.template_type === TemplateType.ClientPortalIdd,
          )
          creditReportIDD = findCreditReportIDD(existingCpIdds)

          // If not found in CP IDDs, check regular IDDs
          if (!creditReportIDD) {
            const existingIdds = clientDocuments?.documents?.filter(
              (document) => document.template_type === TemplateType.Idd,
            )
            creditReportIDD = findCreditReportIDD(existingIdds)
          }

          // Create new Credit IDD if none found
          if (!creditReportIDD) {
            const input = {
              client_id: clientId,
              org_id: creditReportPayloadInput.organisation_id,
              relationship_type: ClientOrganisationRelationshipType.ReferredAdvice,
            }
            await createClientOrganisationRelationship(input.client_id, input.org_id!, input)
          }
        }
      }

      // Step 3: Send invites and create privacy policies
      if ((clientPortalIdd && clientPortalIdd.document?.document_id) || hasIddsForAllClients) {
        const clientInvite = await Promise.all(emailInput.map(async (email) => await postClientInviteTemplate(email)))

        if (clientInvite) {
          // update org payload with filtered ids
          organisationPrivacyPayloadInput.client_ids = clientsIdsThatNeedOrgPrivacyPolicy
          // check if all needed policies already been added
          const hasOrgPrivacyPoliciesForAllClients = organisationPrivacyPayloadInput.client_ids?.length === 0

          //if there are still some missing, render those
          !hasOrgPrivacyPoliciesForAllClients &&
            (await renderTemplate(organisationPrivacyPayloadInput as CdmRenderTemplateRequest))
        }
        return clientInvite
      }
      return null
    },
    postClientInvitesTemplate: async (_, { emailInput }) => {
      return await postClientInviteTemplate(emailInput)
    },
    createOrganisationTemplate: async (_, { input }) => await createOrganisationTemplate(input),
  },
}

export default TemplateResolver
