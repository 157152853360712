import React, { useEffect } from 'react'
import { setContext, setUser } from '@sentry/react'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'

import { useUserContext } from '../context/UserContext'
import RouteBlocker, { RouteBlockerProps } from './RouteBlocker'

interface PrivateRouteProps extends Omit<RouteBlockerProps, 'shouldRender'> {}

const RequireAuth = ({ redirectTo = '/login', ...props }: PrivateRouteProps) => {
  const user = useUserContext()
  const location = useLocation()

  useEffect(() => {
    if (user) {
      setContext('user', {
        id: user.id,
        email: user.email_address,
      })

      setUser({
        id: user.id,
        email: user.email_address!,
      })

      setContext('organisation', {
        id: user.organisation_id,
        name: user.organisation?.name,
      })
    }
  }, [location.pathname, user])

  const shouldRender = Boolean(user?.id)

  // If the user is not logged in, save the current location to redirect to after login
  // Only save the location if it is not the root path
  if (!shouldRender && location.pathname !== '/' && location.pathname !== '/login') {
    Cookies.set('login_redirect', location.pathname)
  }

  return <RouteBlocker {...props} redirectTo={redirectTo} shouldRender={shouldRender} />
}

export default React.memo(RequireAuth)
