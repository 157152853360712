import { IntlShape } from 'react-intl'

import { Case, CaseStatus, ProtectionProductStatus, User } from '@acre/graphql'

import { buildReminderInitialValues, getCaseSelectedMortgage } from './TaskManagement/CaseVerifications.helpers'

export const getRecommendedProtectionProducts = (details: Case) => {
  const { status } = details

  if (status === CaseStatus.Complete) {
    return (
      details.protection_products?.filter(
        (protection_product) =>
          protection_product?.details?.status === ProtectionProductStatus.Current ||
          protection_product?.details?.status === ProtectionProductStatus.Selected,
      ) || []
    )
  } else {
    return (
      details.protection_products?.filter(
        (protection_product) => protection_product?.details?.status === ProtectionProductStatus.Selected,
      ) || []
    )
  }
}

export const setInitialReminderValues = (
  details: Case,
  user: User,
  intl: IntlShape,
  lenderName: string,
  earliestGIProductEndDate?: Date,
) => {
  if (details) {
    const mortgage = getCaseSelectedMortgage(details as Case)
    return buildReminderInitialValues(
      user!,
      intl,
      lenderName,
      mortgage,
      details.preference_mortgage_reason,
      earliestGIProductEndDate,
    )
  }
  return {}
}

export enum TasksLocation {
  CaseOverview = 'CASE_OVERVIEW',
  CaseSidebarDrawer = 'CASE_SIDEBAR_DRAWER',
}
