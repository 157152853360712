import { type CaseDetailsFlag, CaseDetailsFlagFlagType } from '@acre/graphql'

const caseDetailsFlagFixture: CaseDetailsFlag = {
  __typename: 'CaseDetailsFlag',
  flag_id: 'TEST_FLAG_ID',
  flag_type: CaseDetailsFlagFlagType.FlagManualReview,
  created_at: null,
  reviews: [],
}

export default caseDetailsFlagFixture
