import { get, type FieldPath, type FieldValues, FieldPathValue } from "react-hook-form"

/**
 * It mutates the object you pass in. Do not pass actual form state here.
 * In fact, just don't use this function. It exists for one purpose only.
 * 
 * !NEVER EXPORT THIS!
 */
const unsafeDeleteByPath = <TFieldValues extends FieldValues = FieldValues>(path: FieldPath<TFieldValues>, obj: TFieldValues) => {
  const isNested = path.includes('.')

  if (isNested) {
    const splitPath = path.split('.')

    const childPath = splitPath.pop()!
    const parentPath = splitPath.join('.')

    // get ref to parent
    const parent = get(obj, parentPath) as FieldPathValue<TFieldValues, FieldPath<TFieldValues>>

    delete parent[childPath]

  } else {
    delete obj[path]
  }
}

export default unsafeDeleteByPath