import React, { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'

import testHandle from '../../utils/testHandle'
import Icon, { IconTypes } from '../Icon'
import { BannerTypes } from './Banner.types'
import { StyledBanner } from './Banner.styles'

export type BannerProps = {
  text: string | React.ReactNode
  id?: string
  type?: BannerTypes
  showIcon?: boolean
  iconType?: IconTypes
  children?: ReactElement
  fontSize?: number
  reducedPadding?: boolean
  alignCenter?: boolean
  spaceBetween?: boolean
  showTitle?: boolean
  title?: string
  margin?: string
  centerAlign?: boolean
  fullWidth?: boolean
  customBanner?: boolean
}

const Banner = ({
  id,
  text,
  type = BannerTypes.Warning,
  showIcon = true,
  iconType,
  children,
  fontSize = 16,
  title,
  reducedPadding = false,
  alignCenter = false,
  spaceBetween = false,
  showTitle = false,
  margin = '',
  centerAlign = false,
  fullWidth = false,
  customBanner = false,
}: BannerProps) => {
  const dataTestId = id ? testHandle(id) : null

  // Defining this outside of the component causes a compiler error for some reason
  const bannerTypeIconMap: Record<BannerTypes, IconTypes> = {
    [BannerTypes.Success]: IconTypes.BannerSuccess,
    [BannerTypes.Warning]: IconTypes.BannerWarning,
    [BannerTypes.Info]: IconTypes.Tooltip,
    [BannerTypes.Alert]: IconTypes.Exclamation,
  }

  const bannerIcon = iconType ? iconType : bannerTypeIconMap[type]

  return (
    <StyledBanner
      reducedPadding={reducedPadding}
      fontSize={fontSize}
      type={type}
      id={id}
      data-testid={dataTestId}
      iconPassedAsProp={Boolean(iconType)}
      alignCenter={alignCenter}
      spaceBetween={spaceBetween}
      margin={margin}
      centerAlign={centerAlign}
      fullWidth={fullWidth}
    >
      {customBanner ? (
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
            {showIcon && <Icon name={bannerIcon} />}
            {showTitle && (
              <Typography variant="h5" ml={1} fontSize={'14px'}>
                {title}
              </Typography>
            )}
          </Box>
          <Box>
            <p>{text}</p>
          </Box>
        </Box>
      ) : (
        <>
          {showIcon && <Icon name={bannerIcon} />}
          <p>{text}</p>
        </>
      )}

      {children}
    </StyledBanner>
  )
}

export default Banner
